import moment from "moment";

import getConsentDetails from "../api/get-consent-details";
import getConsentTemplates from "../api/get-consent-templates";

import i18n from "../i18n/init";

const consentsModuleConfig = {
  consentSearchFilters: [
    {
      name: "consentId",
      label: i18n.t("common.labels.consent_id"),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.origin_marketing_program"),
    },
    {
      name: "country",
      label: i18n.t("common.labels.country"),
    },
    {
      name: "language",
      label: i18n.t("common.labels.language"),
    },
  ],
  consentTableColumns: [
    {
      field: "consentTemplateKey",
      headerName: i18n.t("common.labels.consent_template_key"),
      sortable: false,
      clickable: true,
      clickableCellData: {
        params: ["consentTemplateKey"],
        api: getConsentTemplates,
      },
      disableToggle: true,
    },
    {
      field: "consentTemplateType",
      headerName: i18n.t("common.labels.consent_template_type"),
      sortable: false,
      truncate: true,
      renderCell: (para) =>
        `${para.row.consentTemplateTypeId} - ${para.row.consentTemplateType}`,
    },
    {
      field: "country",
      headerName: i18n.t("common.labels.country"),
      sortable: false,
      truncate: true,
      renderCell: (para) => `${para.row.countryCode} - ${para.row.country}`,
    },
    {
      field: "language",
      headerName: i18n.t("common.labels.language"),
      sortable: false,
      renderCell: (para) => `${para.row.languageCode} - ${para.row.language}`,
    },
    {
      field: "templateVersionNumber",
      headerName: i18n.t("common.labels.template_version_number"),
      sortable: false,
      renderCell: (para) => `V${para.row.templateVersionNumber}`,
    },
    {
      field: "consentText",
      headerName: i18n.t("common.labels.consent_text"),
      sortable: false,
      parseData: (row) => row.consentText?.replace(/<[^>]*>/g, ""),
      truncate: true,
    },
    {
      field: "lastUpdatedDate",
      headerName: i18n.t("common.labels.last_updated_date"),
      sortable: false,
      renderCell: (para) =>
        para.row.lastUpdatedDate.replace("T", " ").replace(".000Z", ""),
    },
  ],
  consentTemplateKeys: [
    {
      id: "consentText",
      name: i18n.t("common.labels.consent_text"),
    },
    {
      id: "noticeText",
      name: i18n.t("common.labels.notice_text"),
    },
  ],
  consentVersionKeys: [
    {
      id: "templateVersionNumber",
      name: i18n.t("create_consent_template.template_version_number"),
    },
    {
      id: "consentText",
      name: i18n.t("common.labels.consent_text"),
    },
    {
      id: "noticeText",
      name: i18n.t("common.labels.notice_text"),
    },
    {
      id: "createdDate",
      name: i18n.t("common.labels.created_date"),
      hideIfEmpty: true,
      parseData: (row) =>
        row.createdDate &&
        moment(row.createdDate).format("YYYY-MM-DD HH:mm:ss"),
    },
  ],
  consentSearchDefaultFilterState: {
    consentTemplateType: false,
    country: false,
    language: false,
    consentTemplateText: false,
  },
  createConsentIDConstants: {
    footerText: {
      1: i18n.t("create_consent.next_button_text.step_1"),
      2: i18n.t("create_consent.next_button_text.step_3"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
  },
  createVersionsConstants: {
    footerText: {
      1: i18n.t("create_consent_template.proceed_to_versions"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
  },
  consentMappingsTableColumns: [
    {
      field: "consentId",
      headerName: i18n.t("common.labels.consent_id"),
      flex: 0.8,
      sortable: false,
      clickable: true,
      clickableCellData: {
        params: ["consentId"],
        api: getConsentDetails,
      },
      disableToggle: true,
    },
    {
      field: "marketingProgram",
      headerName: i18n.t("common.labels.origin_marketing_program"),
      sortable: false,
      truncate: true,
    },
    {
      field: "country",
      headerName: i18n.t("common.labels.country"),
      sortable: false,
      truncate: true,
    },
    {
      field: "language",
      headerName: i18n.t("common.labels.language"),
      sortable: false,
    },
    {
      field: "lastUpdatedDate",
      headerName: i18n.t("common.labels.last_updated_date"),
      flex: 0.9,
      sortable: false,
      parseData: (row) =>
        row.lastUpdatedDate &&
        moment(row.lastUpdatedDate).format("YYYY-MM-DD HH:mm:ss"),
    },
  ],
};

export default consentsModuleConfig;
