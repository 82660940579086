import { useEffect, useMemo, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useHistory, Link } from "react-router-dom";

import createConsentTemplate from "../../../api/create-consent-template";
import getConsentTemplates from "../../../api/get-consent-templates";
import updateRequest from "../../../api/update-request";
import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import applicationConfig from "../../../config/applicationConfig";
import consentsModuleConfig from "../../../config/consentsModuleConfig";
import languages from "../../../config/languages";
import pageAccessConfig from "../../../config/pageAccessConfig";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";

import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";

import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import handleError from "../../../utilities/handleError";

import isEmpty from "../../../utilities/isEmpty";

import ConfirmationDetails from "./components/ConfirmationDetails";
import ConsentTemplateRequestOutputModal from "./components/ConsentTemplateRequestOutputModal";
import Details from "./components/Details";
import Versions from "./components/Versions";
import createBackendPayload from "./helpers/createBackendPayload";
import useConsentTemplateTypesAndCountries from "./helpers/useConsentTemplateTypesAndCountries";
import validateConsentTemplate from "./helpers/validateConsentTemplate";
import useStyles from "./styles";

const totalSteps = 3;

const NewConsentTemplateContainer = () => {
  const { t } = useTranslation();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const { user } = useUserProfile();
  const { request, setRequest } = useRequest();
  const { addNotification } = useNotifier();
  const classes = useStyles();
  const { requestStatus } = applicationConfig;
  const { createVersionsConstants } = consentsModuleConfig;
  const { consentTemplateTypes, countries } =
    useConsentTemplateTypesAndCountries();
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.manageConsents
  );

  const isRequestRevisionFlow = useMemo(
    () => Object.keys(request).length !== 0,
    [request]
  );
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);
  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [initialStep, setInitialStep] = useState(1);

  const [consentTemplateTypeValue, setConsentTemplateTypeValue] = useState({});
  const [countryValue, setCountryValue] = useState({});
  const [languageValue, setLanguageValue] = useState({});
  const [showDetailsValidation, setShowDetailsValidation] = useState(false);

  const [consentTemplate, setConsentTemplate] = useState();
  const [newConsentTemplateVersions, setNewConsentTemplateVersions] = useState(
    []
  );

  const [mappingResponse, setMappingResponse] = useState([]);
  const [showOutput, setShowOutput] = useState(false);
  const [output, setOutput] = useState({});

  const [alert, setAlert] = useState();
  const [consentTemplateInfo, setConsentTemplateInfo] = useState();

  const showSkeleton = loading && isEmpty(consentTemplateTypeValue);

  const initializeConsentTemplate = async (
    consentTemplateType = consentTemplateTypeValue,
    country = countryValue,
    language = languageValue
  ) => {
    try {
      increaseRequestsCount();
      const data = await getConsentTemplates(
        `${consentTemplateType?.consentTemplateTypeId}-${country?.countryCode}-${language?.languageCode}`
      );
      setConsentTemplate(data);
      return data;
    } catch (error) {
      setConsentTemplate({
        ...consentTemplateType,
        ...country,
        ...language,
      });
      return null;
    } finally {
      decreaseRequestsCount();
    }
  };

  useEffect(() => {
    (async () => {
      const errors = validateConsentTemplate(
        consentTemplateTypeValue,
        countryValue,
        languageValue
      );
      if (Object.keys(errors).length === 0) {
        const data = await initializeConsentTemplate();
        if (data) {
          setConsentTemplateInfo(
            `Consent Template for Key ${data.consentTemplateTypeId}-${data.countryCode}-${data.languageCode} exists, you could create a new version in the next step`
          );
        } else {
          setConsentTemplateInfo(
            `Consent Template for Key ${consentTemplateTypeValue.consentTemplateTypeId}-${countryValue.countryCode}-${languageValue.languageCode} doesn't exist, you could create it in the next step`
          );
        }
      }
    })();
  }, [consentTemplateTypeValue, countryValue, languageValue]);

  useEffect(() => {
    (async () => {
      if (isRequestRevisionFlow) {
        const {
          consentText,
          noticeText,
          consentTemplateType,
          language,
          country,
          templateVersionNumber,
        } = request.requestDetails;
        if (consentTemplateTypes.length === 0 || countries.length === 0) {
          return;
        }
        const revisionConsentTemplateTypeValue = consentTemplateTypes.find(
          (type) => type.consentTemplateTypeId === consentTemplateType
        );
        const revisionLanguageValue = languages.find(
          (l) => l.languageCode?.toLowerCase() === language?.toLowerCase()
        );
        const revisionCountryValue = countries.find(
          (c) => c.countryCode === country
        );
        setConsentTemplateTypeValue({
          ...revisionConsentTemplateTypeValue,
          title: `${revisionConsentTemplateTypeValue.consentTemplateTypeId} - ${revisionConsentTemplateTypeValue?.description}`,
        });
        setLanguageValue({
          ...revisionLanguageValue,
          languageCode: revisionLanguageValue.languageCode.toUpperCase(),
          title: `${revisionLanguageValue.languageCode.toUpperCase()} - ${
            revisionLanguageValue.languageName
          }`,
        });
        setCountryValue({
          ...revisionCountryValue,
          title: `${revisionCountryValue.countryCode} - ${revisionCountryValue.countryName}`,
        });
        setConsentTemplate({
          templateVersionNumber,
          consentText,
          noticeText,
          language,
          country,
          consentTemplateType,
        });
        await initializeConsentTemplate(consentTemplateType, country, language);
        setNewConsentTemplateVersions([
          {
            templateVersionNumber,
            consentText,
            noticeText,
            new: true,
          },
        ]);
      }
    })();
  }, [
    isRequestRevisionFlow,
    request.requestDetails,
    consentTemplateTypes,
    countries,
  ]);

  useEffect(() => {
    if (
      state?.consentTemplate &&
      consentTemplateTypes.length > 0 &&
      countries.length > 0
    ) {
      const stateConsentTemplateTypeValue = consentTemplateTypes.find(
        (type) =>
          type.consentTemplateTypeId ===
          state.consentTemplate.consentTemplateTypeId
      );
      const stateLanguageValue = languages.find(
        (l) =>
          l.languageCode?.toLowerCase() ===
          state.consentTemplate.languageCode?.toLowerCase()
      );
      const stateCountryValue = countries.find(
        (c) => c.countryCode === state.consentTemplate.countryCode
      );
      setConsentTemplateTypeValue({
        ...stateConsentTemplateTypeValue,
        title: `${stateConsentTemplateTypeValue.consentTemplateTypeId} - ${stateConsentTemplateTypeValue?.description}`,
      });
      setLanguageValue({
        ...stateLanguageValue,
        languageCode: stateLanguageValue.languageCode.toUpperCase(),
        title: `${stateLanguageValue.languageCode.toUpperCase()} - ${
          stateLanguageValue.languageName
        }`,
      });
      setCountryValue({
        ...stateCountryValue,
        title: `${stateCountryValue.countryCode} - ${stateCountryValue.countryName}`,
      });
      setConsentTemplate(state.consentTemplate);
      setInitialStep(2);
      setCurrentStep(2);
    }
  }, [state, consentTemplateTypes, countries]);

  const confirmationDetails = useMemo(
    () => [
      {
        label: "Consent Template Type",
        value: consentTemplateTypeValue?.title,
      },
      {
        label: "Country",
        value: countryValue?.title,
      },
      {
        label: "Language",
        value: languageValue?.title,
      },
    ],
    [consentTemplateTypeValue, countryValue, languageValue]
  );

  const consentTemplatesTableColumns = useMemo(
    () => [
      {
        field: "requestId",
        headerName: t("common.labels.request_id"),
        flex: 1,
        sortable: false,
        disableToggle: true,
      },
      {
        field: "consentTemplateType",
        headerName: t("common.labels.consent_template_type"),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <span>
              {params.row?.consentTemplateType?.consentTemplateTypeId}
            </span>
          );
        },
      },
      {
        field: "country",
        headerName: t("common.labels.country"),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return <span>{params.row?.country?.countryCode}</span>;
        },
      },
      {
        field: "language",
        headerName: t("common.labels.language"),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return <span>{params.row?.language?.languageCode}</span>;
        },
      },
      {
        field: "templateVersionNumber",
        headerName: t("create_consent_template.template_version_number"),
        flex: 1,
        sortable: false,
      },
      {
        field: "consentText",
        headerName: t("common.labels.consent_text"),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
        },
      },
      {
        field: "status",
        headerName: t("status.status"),
        flex: 1,
        renderCell: (params) => {
          return (
            <div className={classes.statusWrapper}>
              <StatusBadge
                status={params.value}
                showTooltip
                onTooltipClick={() => {
                  if (params.value === requestStatus.APPROVED) {
                    setShowOutput(true);
                  }
                }}
              />
            </div>
          );
        },
        sortable: false,
      },
    ],
    []
  );

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/consents"
      goToText={t("access_denied.go_to_consents")}
    />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading}
        steps={[
          t("create_consent_template.headings.step_1"),
          t("create_consent_template.headings.step_2"),
          t("create_consent_template.headings.step_3"),
        ]}
        headerText={t("create_consent_template.headerText")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: globalStyles.btn,
                  }}
                  onClick={() => {
                    if (currentStep === initialStep) {
                      history.goBack();
                    } else {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {t("common.back")}
                </Button>
                <Button
                  onClick={async () => {
                    if (loading) {
                      return;
                    }
                    if (currentStep === 1) {
                      const errs = validateConsentTemplate(
                        consentTemplateTypeValue,
                        countryValue,
                        languageValue
                      );
                      if (Object.keys(errs).length !== 0) {
                        setShowDetailsValidation(true);
                        return;
                      }
                      initializeConsentTemplate();
                      setCurrentStep(currentStep + 1);
                    } else if (currentStep === 2) {
                      if (newConsentTemplateVersions.length === 0) {
                        setAlert(
                          t("create_consent_template.add_version_alert")
                        );
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } else if (currentStep === 3) {
                      try {
                        const payload = createBackendPayload(
                          consentTemplate,
                          newConsentTemplateVersions[0]
                        );
                        increaseRequestsCount();
                        if (!isRequestRevisionFlow) {
                          const data = await createConsentTemplate(payload);
                          const { items } = data;
                          setMappingResponse(
                            items.map((item, index) => ({
                              id: index,
                              ...item,
                              status:
                                item.status[0].toUpperCase() +
                                item.status.slice(1),
                            }))
                          );
                          setOutput(items?.[0].output);
                        } else {
                          const data = await updateRequest(
                            request.requestId,
                            payload,
                            applicationConfig.modules.opts
                          );
                          setIsRequestRevisionUpdated(true);
                          setRequest(data);
                          history.goBack();
                          addNotification(
                            t("notifications.request_edited_success"),
                            t("status.success")
                          );
                          return;
                        }
                        setCurrentStep(currentStep + 1);
                      } catch (error) {
                        handleError({
                          error,
                          handle404: false,
                          addNotification,
                        });
                      } finally {
                        decreaseRequestsCount();
                      }
                    }
                  }}
                  classes={{
                    root: globalStyles.btn,
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.7,
                      }}
                    >
                      <CircularProgress
                        color="palette.static.white"
                        size={20}
                      />
                    </Box>
                  )}
                  {createVersionsConstants.footerText[currentStep]}
                </Button>
              </>
            )}
            {currentStep > totalSteps && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/consents"
                >
                  {t("create_consent_template.back_to_consents")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${mappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div className={clsx(classes.newConsentContainer)}>
          {currentStep === 1 && showSkeleton && (
            <>
              <Skeleton height={56} />
              <Skeleton height={56} />
              <Skeleton height={56} />
            </>
          )}
          {currentStep === 1 && !showSkeleton && (
            <>
              <Details
                consentTemplateTypeValue={consentTemplateTypeValue}
                setConsentTemplateTypeValue={setConsentTemplateTypeValue}
                countryValue={countryValue}
                setCountryValue={setCountryValue}
                languageValue={languageValue}
                setLanguageValue={setLanguageValue}
                showValidation={showDetailsValidation}
                consentTemplateTypes={consentTemplateTypes}
                countries={countries}
              />
              {consentTemplateInfo && !loading && (
                <Alert severity="info" style={{ marginTop: 20 }}>
                  <Typography variant="body2">{consentTemplateInfo}</Typography>
                </Alert>
              )}
            </>
          )}
          {(currentStep === 2 || currentStep === 3) && (
            <>
              <ConfirmationDetails details={confirmationDetails} />
              <div className={classes.infoContainer}>
                <Typography variant="h6">
                  {t("create_consent_template.versions")}
                </Typography>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <Versions
                consentTemplateVersions={[
                  ...newConsentTemplateVersions,
                  ...(consentTemplate?.consentTemplateVersions || []),
                ]}
                onAddNewVersion={(newVersion) => {
                  setNewConsentTemplateVersions((versions) => [
                    { ...newVersion, new: true },
                    ...versions,
                  ]);
                  if (alert) {
                    setAlert(null);
                  }
                }}
                onEditNewVersion={(version) => {
                  setNewConsentTemplateVersions((versions) =>
                    versions.map((v) =>
                      v.templateVersionNumber === version.templateVersionNumber
                        ? version
                        : v
                    )
                  );
                }}
                onDeleteNewVersion={(version) => {
                  setNewConsentTemplateVersions((versions) =>
                    versions.filter(
                      (v) =>
                        v.templateVersionNumber ===
                        version.templateVersionNumber
                    )
                  );
                }}
              />
              {alert && (
                <div>
                  <Alert style={{ marginTop: 20 }} severity="error">
                    {alert}
                  </Alert>
                </div>
              )}
            </>
          )}
          {currentStep === 3 && (
            <Versions
              consentTemplateVersions={newConsentTemplateVersions}
              viewOnly
            />
          )}
          {currentStep > 3 && (
            <div
              style={{
                height: 300 * mappingResponse.length + 60,
                maxHeight: "calc(100vh - 100px)",
                overflow: "hidden",
              }}
            >
              <Table
                columns={consentTemplatesTableColumns}
                rows={mappingResponse}
              />
            </div>
          )}
        </div>
      </InputFlow>
      {showOutput && (
        <ConsentTemplateRequestOutputModal
          isOpen={showOutput}
          isLoading={loading}
          requestId={mappingResponse[0].requestId}
          data={output}
          setShowOutput={() => setShowOutput(false)}
        />
      )}
    </>
  );
};

export default NewConsentTemplateContainer;
