module.exports = {
  common: {
    back: "BACK",
    close: "Close",
    close1: "CLOSE",
    close2: "close",
    cancel: "CANCEL",
    cancel1: "Cancel",
    previous: "Previous",
    approve: "APPROVE",
    reject: "REJECT",
    return: "RETURN",
    deleted: "DELETED",
    existing: "EXISTING",
    global: "Global",
    language: "Language",
    modify: "MODIFY MAPPING",
    manage_responses: "MANAGE RESPONSES",
    NA: "N/A",
    save: "SAVE",
    next: "Next",
    submit: "SUBMIT",
    confirm_cancel: "Confirm Cancel",
    loading: "Loading",
    loading_dots: "Loading ...",
    no_matches_found: "No matches found",
    consumer_data_domain: "Consumer Reference Services (CRS)",
    new: "NEW",
    new_trait: "New Trait",
    new_event: "New Event",
    new_source: "New Data Source",
    new_event_property: "New Event Property",
    add_datasource: "ADD DATA SOURCE",
    data_source_id: "Data Source ID",
    filter_by: "Search by",
    of: "of",
    done: "DONE",
    yes: "Yes",
    add: "Add",
    update: "Update",
    updated: "UPDATED",
    no: "No",
    ok: "OK",
    retry: "RETRY",
    go_to_home: "Go to home",
    scope: "Scope",
    logout: "Logout",
    labels: {
      mps: "MPs",
      region: "Region",
      regions: "Regions",
      events: "Events",
      traits: "Traits",
      trait: "Trait",
      usecase: "Use Cases",
      dcf_usecase: "DCF Use Cases",
      utilities: "Utilities",
      consents: "Consents",
      dummy: "Dummy",
      manage_responses: "Manage Responses",
      trait_response: "Trait Response",
      event_response: "Event Response",
      trait_id: "Trait ID",
      insight_id: "Insight ID",
      consent_template: "Consent Template",
      consent_template_key: "Consent Template Key",
      consent_template_type: "Consent Template Type",
      consent_use_case: "Use Case",
      consent_use_case_sub_text:
        "Use this template for registration on P&G owned platform App",
      country: "Country",
      language: "Language",
      business_org_unit: "Business Org Unit",
      template_version_number: "Latest Version",
      consent_template_text: "Consent Template",
      notice_text: "Notice Text",
      created_date: "Created Date",
      last_updated_date: "Last Updated Date",
      trait_name: "Trait Name",
      insight_name: "Insight Name",
      insight_description: "Insight Description",
      compute_frequency: "Compute Frequency",
      processing_type: "Processing Type",
      input_traits: "Input Traits",
      output_trait: "Output Trait",
      source_details: "Source Details",
      destination_details: "Destination Details",
      trait_description: "Trait Description",
      data_type: "Data Type",
      data_class: "Data Class",
      trait_type: "Trait Type",
      ecosystem_trait_name: "Ecosystem Trait Name",
      ecosystem_trait_type: "Ecosystem Trait Type",
      personal_data: "Personal Data",
      health_data: "Health Data",
      health_data_risk_types: "Health Data Risk Types",
      manage_mappings: "Manage Mappings",
      view_mappings: "View Mappings",
      description: "Description",
      marketing_programs: "Marketing Programs",
      ecosystem_details: "Ecosystem Details",
      trait_responses: "Trait Responses",
      marketing_program: "Marketing Program",
      marketing_program_name: "Marketing Program Name",
      status: "Status",
      source_type: "Source Type",
      source_name: "Data Source Name",
      segment_source_name: "Segment Source Name",
      source_description: "Source Description",
      source_id: "Source ID",
      survivorship_rules: "Survivorship Rules",
      trait_response_types: "Trait Response Types",
      marketing_program_number: "Marketing Program Number",
      marketing_program_description: "Marketing Program Description",
      trait_response_local_language: "Trait Response (Local Language)",
      global_standard_response: "Global Standard Response",
      contact_point_types: "Contact Point Types",
      marketing_program_ecosystems: "Marketing Program Ecosystems",
      country_code: "Country Codes",
      business_name: "MP Business Name",
      brand_name: "MP Brand List",
      marketing_program_status: "Status",
      legal_entity: "Legal Entity",
      geo_region_code: "Geo Region Code",
      business_source_name: "Business Source Name",
      ecosystem_source_details: "Ecosystem Source Details",
      view_details: "View Details",
      back_to_traits: "BACK TO TRAITS",
      back_to_events: "BACK TO EVENTS",
      back_to_insight: "BACK TO INSIGHTS",
      view_request_status: "VIEW REQUEST STATUS",
      proceed_to_mapping: "PROCEED TO MAPPING",
      proceed_to_confirmation: "PROCEED TO CONFIRMATION",
      confirm_and_submit: "CONFIRM AND SUBMIT",
      my_requests: "My Requests",
      opt_id: "Opt ID",
      opt_details: "Opt Details",
      opt_text: "Opt Text",
      job_id: "Job ID",
      job: "Job",
      job_name: "Job Name",
      service_name: "Service Name",
      review_request: "Review Request",
      review_request1: "REVIEW REQUEST",
      event_name: "Event Name",
      event_description: "Event Description",
      event_category: "Event Category",
      event_type: "Event Type",
      event_sub_type: "Event Sub Type",
      gaca_event_name: "GACA Event Name",
      consumer_action_type: "Consumer Action Type",
      opt_mappings: "Opt Mappings",
      events_responses: "Event Property Responses",
      request_id: "Request ID",
      requestDate: "Request Date",
      create_opt_mapping: "CREATE OPT MAPPING",
      message: "Message",
      error_message: "Error Message",
      source: "Data Source",
      sources: "Data Sources",
      opts: "Opts",
      back_to_opts: "Back to Opts",
      comment: "Comment",
      marketing_program_business_name: "Marketing Program Business Name",
      starting_ecosystems: "Starting Ecosystems",
      starting_channels: "Starting Channels",
      requester: "Requester",
      name: "Name",
      event_property_name: "Event Property Name",
      use_case_name: "Use Case Name",
      new_marketing_program: "New Marketing Program",
      clear_search: "Clear Search",
      ecosystems: "Ecosystems",
      view_request: "VIEW REQUEST",
      reviewer: "Reviewer",
      my_source_approvals: "My Sources Approvals",
      channel: "Channel",
      trait_names: "Trait Names",
      segment_source: "Segment Source",
      back_to_sources: "BACK TO SOURCES",
      new_segment_source: "New Segment Source",
      my_traits_approvals: "My Traits Approvals",
      trait_use_case_name: "Trait / Use Case Name",
      my_approvals: "My Approvals",
      monitoring: "Monitoring",
      jobs_monitoring: "Jobs Statistics",
      ecosystem: "Ecosystem",
      entity: "Entity",
      ecosystem_marketing_program_name: "Ecosystem Marketing Program Name",
      ecosystem_marketing_program_key: "Ecosystem Marketing Program Key",
      ecosystem_marketing_program_instance:
        "Ecosystem Marketing Program Instance",
      entity_type: "Entity_Type",
      new_mp: "CREATE A NEW MP",
      new_mp_2: "Map MP Ecosystems",
      back_to_marketing_programs: "BACK TO MARKETING PROGRAMS",
      my_marketing_program_requests: "My Marketing Program Requests",
      my_marketing_program_approvals: "My Marketing Program Approvals",
      brand_program_name: "Brand/Program Name",
      brand_short_name: "Brand/Program Short Name",
      consent_id: "Consent ID",
      origin_marketing_program: "Origin Marketing Program",
      consent_text: "Consent Text",
      // insight_name: "Insight Name",
      // insight_description: "Insight Description",
      sql_custom_indecator: "SQL Custom Indicator",
      output_traits: "Output Trait",
      // input_traits: "Input Traits",
      sql_expression: "SQL Expression",
    },
    texts: {
      marketing_program_and_ecosystem_traits_map:
        "Select at least one marketing program and one ecosystem to map these traits to",
      request_details: "Please find the request details below",
      input_not_required: "This input is not required",
    },
    errors: {
      only_letters_numbers: "Only letters and numbers are allowed",
      character_limit_error: "Max {{count}} characters are allowed",
      less_than_value: "{{field}} value should be less than {{max}}",
    },
  },
  notifications: {
    request_edited_success: "Request edited succesfully",
    approved_success: "Request Approved successfully",
    rejected_success: "Request(s) Rejected successfully",
    returned_success: "Request(s) Returned succesfully",
    bulk_approval_success:
      "Bulk approval request will be processed within few minutes. Stay tuned!",
  },
  prompt: {
    progress_lost:
      "The Progress will be lost. Are you sure you want to proceed?",
  },
  status: {
    all: "All",
    status: "Status",
    failed: "Failed",
    running: "Running",
    pending: "Pending",
    success_partial: "Success Partial",
    success: "Success",
    processing: "Processing",
    approved: "Approved",
    rejected: "Rejected",
    returned: "Returned",
    error: "Error",
  },
  type: {
    modules: "Modules",
    all: "All",
  },
  table_filters: {
    region_labels: {
      glo: "GLOBAL",
      ama: "AMA",
      asia: "ASIA",
      europe_enterprise: "EUROPE ENTERPRISE",
      europe_imea: "EUROPE/IMEA",
      greater_china: "GREATER CHINA",
      latin_america: "LATIN AMERICA",
      north_america: "NORTH AMERICA",
      wnd_region: "WW N DTL REGION",
    },
  },
  home_page: {
    title: "Welcome to the Consumer Reference Services Portal",
    description1:
      "The Consumer Reference Services is the DNA of our consumer data platforms - defining the data about our data. There are several consumer traits and events that we are collecting, and these need quality to ensure that you are able to maximize the value from the data through hands-on data mastery.",
    description2: "You can learn more about what we are and what we do.",
    learn_more: "LEARN MORE",
    user_guide: "USER GUIDE",
    contact_us: "CONTACT US",
    navigation: {
      traits: {
        title: "TRAITS",
        description: "Manage Traits",
      },
      sources: {
        title: "SOURCES",
        description: "Manage Sources",
      },
      my_tasks: {
        title: "MY TASKS",
        description: "My Requests and Approvals.",
      },
      opts: {
        title: "OPTS",
        description: "Manage Opt Mappings and Opt Traits.",
      },
      mps: {
        description: "Manage Marketing Programs",
      },
      events: {
        title: "EVENTS",
        description: "Manage Events",
      },
      monitoring: {
        title: "Monitoring",
        description: "Manage Jobs",
      },
      usecase: {
        title: "USE CASES",
        description: "Manage Use Cases",
      },
      utilities: {
        title: "UTILITIES",
        description: "Explore CRS APIs",
      },
      consents: {
        title: "CONSENTS",
        description: "Manage Notice and Consents",
      },
      dummy: {
        title: "DUMMY",
        description: "Dummy",
      },
      configuration: {
        title: "CONFIGURATION",
        description: "Explore CRS APIs",
      },
    },
  },
  table_header: {
    date_label: "Request Date Range",
    date_validation: "End date should be after start date",
  },
  new_trait: {
    example_values: "Example Values",
    trait_input_type: "Trait Input Type",
    frequency_of_data_change: "Frequency of Data Change",
    select_option: "Select an option",
    personal_data: "This data is PERSONAL (PII) data",
    sensitive_data: "This data is SENSITIVE (NON-PII) data",
    health_data: "This data is HEALTH data",
    health_data_risk_type: "Health Data Risk Type",
    health_data_risk_types: "Health Data Risk Types",
    technical_details:
      "This trait will be created with the following technical details in each of the ecosystems",
    unsaved_inputs_warning:
      "Are you sure you want to cancel? Any unsaved inputs will be lost!",
    trait_response_type: "Trait Response Type",
    survivorship_rule: "Survivorship Rule",
    placeholders: {
      trait_name: "Enter trait name",
      example_values:
        "Indicate possible answers that requesters can answer to this question",
      trait_description:
        "Describe the trait you are trying to create-what question are you asking consumers? What are some sample answers?",
    },
    ecosystem: {
      misc_property: "Ecosystem Trait Misc Property",
      placeholders: {
        trait_name: "Enter Ecosystem trait name",
        trait_type: "Enter Ecosystem trait type",
        misc_property: "Enter Ecosystem Trait Misc Property 1",
      },
    },
    errors: {
      only_letters_numbers_spaces:
        "Only letters and numbers and spaces are allowed",
      trait_name: {
        error_1: "Trait name should not start with the word 'trait'",
        error_2: "Trait name should not end with 'idValue",
        error_3: "This trait name is too short.",
        error_4: "Trait with this name already exists",
        error_5: "Validating Trait name...",
        error_6: "Trait Name validated Successfully",
      },
      trait_description: {
        error_1: "Trait description should not be same as trait name",
        error_2:
          "Trait description is too short at least must contain 5 non-space characters",
        error_3:
          "Trait description is too short and must be more than three words",
      },
      example_values: "Example values should not be same as trait name",
      ecosystem_traits: {
        eco_trait_name: {
          error_1:
            "Trait name is too long for this system. Please provider a shorter name.",
          error_2:
            "Only letters and numbers and [ {{characters}} ] characters are allowed",
          error_4:
            "Trait Name is too short and must be more than three characters",
        },
        eco_trait_type: {
          error_1:
            "Trait Type is too short and must be more than three characters",
        },
        eco_system_misc_property: {
          error_1:
            "Eco System Trait Misc Property is too short and must be more than three characters",
        },
      },
    },
    dialogs: {
      confirm_trait_classification: "Confirm Trait Classification",
      trait_classification:
        "Please classify the trait accordingly to the type of data it is:",
      trait_text_warning:
        "Do you confirm that you have tagged the trait appropriately as PERSONAL, SENSITIVE, and/or HEALTH data?",
      opt_text_confirmation:
        "Please confirm that you have double checked this with your legal team by clicking below to proceed",
      opt_text_team_check:
        "Otherwise, please stop and go check with them before proceeding",
      approved_by_legal: "Yes, this was approved by legal",
      opt_mapping_warning:
        "Are you sure you want to remove this opt mapping ? This will delete any input you have provided so far!",
      opt_id_warning:
        "Are you sure you want to remove this opt details ? This will delete any input you have provided so far!",
      yes_confirm: "YES I CONFIRM",
      cancel: "Cancel",
    },
  },
  traits_traits_responses: {
    new_traits_responses: "New Trait Response",
    new_global_standard_responses: "New Global Standard Response",
    new_event_responses: "New Event Property Response",
    headings: {
      events_flow_step_1: "Select Event",
      step_1: "Select Trait",
      step_2: "Map Responses",
      step_3: "Preview & Confirm",
    },
  },
  create_trait: {
    no_suggestion_match: "No suggestion match ?",
    new_trait: "Create a New Trait",
    add_another_trait: "Add another trait",
    mapping_traits: "You are trying to map these traits",
    trait_removal_warning:
      "Are you sure you want to remove this trait? This will delete any input you have provided so far!",
    steps: {
      step_1: "Type trait you are trying to create",
      step_3:
        "Confirm the following details of your new trait creation and trait mapping.",
    },
    headings: {
      step_1: "Add Traits",
      step_2: "Mapping",
      step_3: "Preview & Confirm",
    },
  },
  create_event: {
    event_removal_warning:
      "Are you sure you want to remove this event? This will delete any input you have provided so far!",
    no_suggestion_match: "No suggestion match ?",
    new_event: "Create a New Event",
    action_taken_by: "Action Taken By",
    placeholders: {
      event_name: "Enter Event Name",
      event_description: "Describe the event you are trying to create",
      event_type: "Enter 2 Character Event Type if known",
      event_sub_type: "Enter Numerical Event Sub Type if known",
      gaca_event_name: "Enter GACA Event Name if known",
    },
    errors: {
      event_name: {
        message_1: "Validating Event name...",
        error_1: "Event with this name already exists",
        message_2: "Event Name validated Successfully",
      },
      event_description: {
        error_1: "Event description should not be same as trait name",
        error_2:
          "Event description is too short at least must contain 5 non-space characters",
        error_3:
          "Event description is too short and must be more than three words",
      },
      select_event: "Please select a event",
      gaca_event_name: "GACA Event Name should be in snake_case only",
    },
    dialogs: {
      property_classification:
        "Please classify the event property accordingly to the type of data it is:",
    },
    create_event_property: {
      property_name: "Property Name",
      property_description: "Property Description",
      data_type: "Data Type",
      sample_values: "Sample Values",
      required: "Required for Event",
      placeholders: {
        enter_property_name: "Enter Property Name",
        property_description:
          "Describe the event property you are trying to create",
        data_type: "Enter Data Type (e.g., string)",
        enter_sample_values: "Please Enter Sample Values",
      },
      new_property: "New Property",
      delete_property:
        "Are you sure you want to delete this property? This will delete any input you have provided so far!",
      property_heading: "Event properties:",
    },
  },
  create_source: {
    new_trait: "Create a New Source",
    steps: {
      step_1: "Enter the fields for the source you are trying to create",
      step_2: "Confirm the following details of your new source creation.",
    },
    headings: {
      step_1: "Add Data Sources",
      step_2: "Mapping",
      step_3: "Preview & Confirm",
    },
    map_to_segment_source: "Map to Segment Source",
    next_steps: "Next Steps",
    map_some_traits: "Map Some Traits",
    map_source_to_existing: "Map source to existing /",
    segment_automation_message:
      "Segment automation is not enabled for this marketing program, hence, any new sources will not be automatically created in Segment. This would also mean that Segment Tracking Plans will not be updated as well. If you would like to enable Segment automation for this marketing program, ",
  },
  disconnect_sources: {
    disconnect_sources: "Disconnect Sources",
    manage_sources: "Manage Sources",
    mapped_mpns: "Mapped Marketing Program(s)",
    disconnected_mpns: "Disconnected Marketing Program(s)",
    source_name: "Data Source",
    data_source_revision: "Data source revision",
    disconnect_mpn_confirmation:
      "Are you sure you want to disconnect this marketing program from this source?",
    headings: {
      step_1: "Select Source",
      step_2: "Disconnect MPNs",
      step_3: "Preview & Confirm",
    },
    mapped_marketing_programs:
      "Marketing Program(s) disconnected from the data source is listed below",
    view_marketing_program: "View Disconnected Marketing Programs",
    manage_disconnect: "Disconnect Sources",
    disconnect_output_modal_title:
      "Data Source and Marketing Program(s) listed below have been disconnected in CRS",
  },
  create_marketing_program: {
    newMp: "Create New MP",
    headerText: "Manage Marketing Program",
    manage_ecosystem_details: "Would you like to manage ecosystem details?",
    no_changes_to_ecosystems:
      "For existing marketing programs, Please make any changes to the ecosystems to proceed",
    mp_removal_warning:
      "Are you sure you want to remove this marketing program? This will delete any input you have provided so far!",
    delete_marketing_ecosystem_confirmation:
      "Are you sure want to remove this Marketing Program Ecosystem?",
    placeholders: {
      selectMp: "Search for MP or Create New MP",
    },
    headings: {
      step_1: "Select Marketing Program",
      step_2: "Manage Ecosystems",
      step_3: "Preview & Confirm",
    },
    confirm: "Confirm",
    submit: "Submit",
  },
  create_use_case: {
    headings: {
      step_1: "Use Case Details",
      step_2: "Mapping",
      step_3: "Preview & Confirm",
    },
    steps: {
      step_1: "Input details about the use case",
      step_2:
        "Select the marketing program you would like to manage for this use case",
      step_3: "Confirm Use Case & Trait Associations",
    },
    revision: {
      headings: {
        step_1: "Use Case Revision",
        step_3: "Confirm Use Case Revision",
        step_4:
          "Request to Revise Use Case and it's trait associations Submitted Successfully",
      },
      steps: {
        step_1: "Review/Change details about this use case",
        step_2:
          "Verify the marketing program/associations you would like to manage for this use case",
        step_3: "Confirm Use Case & Trait Associations",
        step_4: "Please find the revised request details below",
      },
    },
    use_case_description: "Use Case Description",
    use_case_id: "Use Case ID",
    marketing_program: "Please Select Marketing Program",
    no_traits_mapped: "No traits mapped for this use case + marketing program",
    trait_deletion_warning:
      "Please consult first with your respective regional data manager before deleting any traits from the use case.",
    trait_deletion_approval_warning:
      "Some traits are deleted from the use case",
    property_deletion_approval_warning:
      "Some event properties are deleted from the use case",
    trait_responses_deletion_approval_warning:
      "Some trait responses are deleted",
    property_responses_deletion_approval_warning:
      "Some event property responses are deleted",
    add_trait: "Add a Trait",
    trait_removal_warning:
      "Are you sure want to remove the following trait from the use case for scope {{scope}} ? You can add it back later",
    errors: {
      use_case_name_error: "Use case name is too short",
      use_case_description: {
        error_1: "Use case name and description shouldn't be same",
        error_2: "Use case description is too short",
        error_3: "Use case description must contain atleast four words",
      },
      existing_use_case: "Use Case already exists",
      use_case_name_scope_error:
        "Please use scope within existing use cases to create a mapping at region/legal entity/marketing program level",
    },
    traits_use_case_mapping:
      "Traits mapped to the use case and scope is listed below",
    events_use_case_mapping:
      "Event properties mapped to the use case and scope is listed below",
    associated_traits: "Associated Traits",
    associated_event_properties: "Associated Event Properties",
    traits: {
      traitId: "Trait ID",
      globalTraitName: "Global Trait Name",
      dataType: "Data Type",
      personalData: "Personal Data",
    },
    trait_added: "Trait added to use case",
    trait_added_preview: "Trait will be added to use case",
    event_property_added: "Event Property added to use case",
    event_property_delete: "Event Property will be deleted",
    trait_deleted: "Trait deleted from use case",
    trait_deleted_preview: "Trait will be deleted from use case",
    back_to_use_cases: "Back to Use Cases",
    no_traits_changes: "No changes done",
    no_events_changes: "No changes done",
    no_event_property_changes: "No Event Property Changes done",
    new_use_case: "Use Case created successfully",
    new_use_case_preview: "Use Case will be created successfully",
    update_use_case: "Use Case updated successfully",
    update_use_case_preview: "Use Case will be updated successfully",
  },
  create_consent: {
    headerText: "Consent Management",
    headings: {
      step_1: "Consent ID",
      step_2: "Consent Details",
      step_3: "Preview",
    },
    next_button_text: {
      step_1: "Proceed to Consent Details",
      step_2: "Proceed to Preview",
      step_3: "Proceed to Preview",
    },
    dialogs: {
      confirm_usecase_classification: "Use Case Instructions",
      yes_confirm: "YES I CONFIRM",
      cancel: "Cancel",
      confirmation:
        "\nI have read and understood the instructions and I confirm that I want to create Consent ID for this specific use case only.",
    },
  },
  create_consent_template: {
    headerText: "Consent Management",
    headings: {
      step_1: "Template Details",
      step_2: "Versions",
      step_3: "Preview & Confirm",
    },
    placeholders: {
      consent_template_type: "Select Consent Template Type",
      consent_use_case: "Select your Use Case for collecting Consent",
      country: "Select Country",
      language: "Select Language",
    },
    template_version_number: "Template Version Number",
    proceed_to_versions: "Proceed to versions",
    versions: "Versions",
    emptyVersionsMessage: "No Versions added",
    back_to_consents: "Back to Consents",
    new_version: "New Version",
    add_version_alert: "Add version to continue",
    delete_version: "Are you sure you want to delete this version",
    disable_new_version_msg: "Only 1 New Version can be added at a time",
    new_template_success_msg: "New Template Version added successfully",
  },
  revise_source: {
    steps: {
      step_1: "Edit the fields for the source.",
      step_2: "Confirm the following details of your source revision.",
    },
    headings: {
      step_1: "Source Revision",
      step_2: "Confirm Source Revision",
    },
  },
  create_segment_source: {
    headings: {
      step_1: "Segment Source Mapping",
      step_2: "Confirm Segment Source mapping",
      step_3:
        "Request to create segment source mapping submitted successfully!",
    },
    steps: {
      step_1: "Select Data Sources and Segment Sources to Map",
      step_2: "Confirm the mapping of data source and segment source",
    },
    new_segment_source: {
      source_integration_type: "Source Integration Type",
    },
    segment_source_removal_warning:
      "Are you sure you want to remove this segment source? This will delete any input you have provided so far!",
  },
  new_source: {
    consumer_facing_description: "Consumer Facing Description",
    description_of_process: "Description of Process",
    kind_of_data_collected: "Kind Of Data Collected",
    business_facing_name: "Business Facing Name",
    is_data_source_website: "Is this data source a website?",
    select_yes_or_no: "Select Yes/No",
    data_source_url: "Data Source URL",
    registration_page_url: "Registration Page URL",
    system_displaying_page: "System Displaying Page",
    is_managed_by_agency: "Is the consumer data managed by an external agency?",
    agency_name: "Agency Name",
    is_source_controller_of_data:
      "Is this source a co-controller of data? If unsure, select No",
    ropa_id: "Ropa ID",
    tooltips: {
      source_description: "Describe the source",
      consumer_facing_description:
        "If a consumer asked you where did you get my data, how would you describe this source?",
      description_of_process:
        "Briefly describe how the data is being collected. Are you asking users to fill out a paper form? Asking them to fill it out on the website?",
      data_source_url: "Enter Data Source URL",
      registration_page_url: "Enter the production URL of the data source",
      system_displaying_page:
        "Indicate the backend system that your website is running on",
      agency_name:
        "Enter the name of the agency that processes your consumer data",
      ropa_id: "Enter the ROPA ID of your data source",
      source_name: "Input a simple source name",
    },
    placeholders: {
      source_description: "Enter Source Description",
      source_type: "Select Source Type",
      consumer_facing_description: "Enter Consumer Facing Description",
      description_of_process: "Enter Description of Process",
      business_facing_name: "Enter Business Facing Name",
      data_source_url: "Enter Data Source URL",
      registration_page_url: "Enter Registration Page URL",
      system_displaying_page: "Enter System Displaying Page",
      agency_name: "Enter Agency Name",
      ropa_id: "Enter Ropa ID",
      marketing_program: "Example: 10 - JPN Household",
      source_name: "Enter Source Name",
    },
    errors: {
      source_name_error:
        "Only letters and numbers and (),-,&,' characters are allowed",
      source_name_prefix: "Source Name must be prefixed with {{sourceName}}",
      source_name_suffix:
        "Source Name must include suffix other than marketing program",
      source_description: {
        error_1: "Source Description must not be same as source name",
        error_2:
          "Source description is too short at least must contain 5 non-space characters",
        error_3:
          "Source description is too short and must be more than three words",
      },
      consumer_facing_description: {
        error_1: "Consumer Facing Description must not be same as source name",
        error_2:
          "Consumer Facing Description is too short at least must contain 5 non-space characters",
        error_3:
          "Consumer Facing Description is too short and must be more than three words",
      },
      description_of_process: {
        error_1: "Description of process must not be same as source name",
        error_2:
          "Description of process is too short at least must contain 5 non-space characters",
        error_3:
          "Description of process is too short and must be more than three words",
      },
      business_facing_name: {
        error_1:
          "Business Facing Name should not be same as Marketing Program Description",
      },
      valid_url: "Should be a valid URL in the format (protocol://domain)",
    },
  },
  new_segment_source: {
    data_source: "Data Source",
    source_integration_type: "Source Integration Type",
    marketing_program: "Please select marketing program first",
    placeholders: {
      data_sources:
        "Type a data source name... (example: 1100 - i50/a/Mult/Multi)",
      segment_source: "Example: 15 - USA Luvs OPT (1150)",
    },
  },
  errors: {
    field_required: "This field is required.",
  },
  reuse_traits: {
    you_are_reusing_trait: "You are reusing the trait",
    reuse_multiple: "Reuse Multiple",
    reuse: "REUSE",
    table_columns: {
      usecase_id: "Use Case ID",
      mesage1: "message",
      status1: "status",
      trait_name1: "traitName",
    },
    headings: {
      step_1_a: "Reuse Traits [Batch]",
      step_1_b: "Reuse Trait",
      step_2: "Confirm Mapping",
      step_3: "Request to reuse trait(s) submitted successfully",
    },
    steps: {
      step_1:
        "Select at least one marketing program and one ecosystem to map this to",
      step_2:
        "Confirm the following details you would like to create mappings for:",
    },
  },
  manage_traits_responses: {
    trait_response_revision: "Trait Response Revision",
    event_property_response_revision: "Event Property Response Revision",
    manage: "MANAGE TRAIT RESPONSES",
    add_traits_response: "ADD NEW TRAIT RESPONSE",
    add_global_standard_response: "ADD NEW GLOBAL STANDARD RESPONSE",
    add_event_response: "ADD NEW EVENT PROPERTY RESPONSE",
    new_traits_response: "New Trait Response",
    new_event_response: "Associated Event Property Responses",
    trait_response_creation: "Trait Response Creation",
    event_response_creation: "Event Property Response Creation",
    event_response_management: "Event Property Response Management",
    input_details_about_traits_response: "Input details about Traits Response",
    delete_traits_responses_confirmation:
      "Are you sure you want to remove this trait response?",
    back_to_traits_responses: "BACK TO TRAIT RESPONSES",
    back_to_events_responses: "BACK TO EVENT RESPONSES",
    no_traits: "No trait responses are mapped",
    no_global_standard_responses: "No Global Standard Responses are mapped",
    no_event_responses: "No Event Property Responses are mapped",
    trait_already_mapped: "Given Trait Response is already mapped",
    global_standard_response_already_mapped:
      "Given Global Standard Response is already mapped",
    new_trait_responses: "New Trait Responses",
    associated_trait_responses: "Associated Trait Responses",
    new_global_standard_response: "New Global Standard Response",
    submit: "SUBMIT",
    associated_global_standard_responses:
      "Associated Global Standard Responses",
    global_standard_responses_created:
      "Global Standard Response created successfully",
    global_standard_responses_updated:
      "Global Standard Response updated successfully",
    global_standard_responses_deleted:
      "Global Standard Response deleted successfully",
    trait_responses_created: "Trait Response created successfully",
    trait_responses_updated: "Trait Response updated successfully",
    trait_responses_deleted: "Trait Response deleted successfully",
    event_responses_created: "Event Response created successfully",
    event_responses_updated: "Event Response updated successfully",
    event_responses_deleted: "Event Response deleted successfully",
    delete_global_standard_response:
      "Are you sure you want to remove this Global Standard Response?",
    request_page_modal_subtitle:
      "Trait responses mapped to the scope is listed below",
    headings: {
      step_1:
        "Please select Trait and Marketing Program for which trait response need to be created",
      step_2_a: "Please add new trait responses",
      step_2_b: "Please add new global standard responses",
      step_3_a: "Review Trait Response details",
      step_3_b: "Review global standard responses",
    },
    placeholders: {
      trait_response_local: "Please enter trait response (local language)",
      trait_response_english: "Please enter trait response (english)",
      global_standard_response: "Please enter a global standard response",
      event_response_local:
        "Please enter event property response (local language)",
      event_response_english: "Please enter event property response (english)",
    },
    alias_removal_warning:
      "Are you sure you want to remove this Alias? This will delete any input you have provided so far!",
  },
  manage_events_responses: {
    request_page_modal_subtitle:
      "Event responses mapped to the scope is listed below",
  },
  manage_use_cases: {
    option: "Option",
    manage: "MANAGE USE CASE",
    reuse: "REUSE THESE TRAITS",
    table_columns: {
      use_case_id: "Use Case ID",
      use_case_description: "Use Case Description",
      related_traits: "Related Traits",
      related_event_properties: "Related Event Properties",
    },
  },
  dcf_use_cases: {
    table_columns: {
      crs_use_case_id: "Use Case ID",
      pipeline_name: "Pipeline Name",
      schema_validation: "Schema Validation",
      data_quality_rules: "Data Quality Rules",
      data_payload_transformation: "Data/Payload Transformation",
      ml_functions: "ML Functions",
      implemented_in_below_microservices: "Implemented in microservices",
    },
    services: "Services",
    schema_template: {
      schema: "Schema",
      schema_id: "Schema ID",
      schema_version: "Schema Version",
      schema_name: "Schema Name",
      schema_template: "Schema Template",
      empty_message: "No Schema Templates",
    },
    rule: {
      rule: "Rule ID",
      rule_version: "Rule Version",
      rule_name: "Rule Name",
      rule_type: "Rule Type",
      validation_rule: "Validation Rule",
      rule_failure_outcome: "Rule Failure Outcome",
      empty_message: "No Data Quality Rules",
      sequence_number: "Sequence Number",
    },
    functions: {
      empty_message: "No Functions",
      function_id: "Function ID",
      function_version: "Function Version",
      function_name: "Function Name",
      function_type: "Function Type",
      sequence_number: "Sequence Number",
      transformation_function: "Transformation Function",
    },
    exceptions: "Exceptions",
    data_transformation_scopes: "Data Transformation Scopes",
    applicable_legal_entities: "Applicable Legal Entities",
    applicable_marketing_programs: "Applicable Marketing Programs",
    data_quality_rules_scopes: "Data Quality Rules Scopes",
    ml_function_scopes: "ML Function Scopes",
  },
  manage_mps: {
    manage: "MANAGE MP",
    create: "CREATE MP",
    reuse: "REUSE TRAITS",
  },
  manage_events: {
    option: "Option",
    use: "USE EVENT",
    manage_events: "MANAGE EVENT",
    table_columns: {
      event_property: "Event Property",
      event_value_type: "Event Value Type",
    },
    new_events: "Create Event/Property",
  },
  create_events: {
    event_removal_warning:
      "Are you sure you want to remove this event? This will delete any input you have provided so far!",
    headings: {
      step_1: "Add Event",
      step_2: "Mapping",
      step_3: "Preview & Confirm",
    },
    no_event_properties_added: "No Property added",
    create_event_property: "Create New Property",
  },
  use_events_container: {
    select_property: "Select Property",
    latest: "LATEST",
    labels: "Labels",
    all: "ALL",
    confirm: "Confirm",
    submit: "Submit",
    property_name: "Property Name",
    valueType: "Value Type",
    add_property: "Add a property",
    only_lytics_allowed: "Only Lytics is allowed for now",
    associated_event_properties: "Associated Event Properties",
    select_properties_for_setup:
      "Select the properties from {{eventName}} that you would like to set up",
    back_to_events: "Back to Events",
    steps: {
      step_1:
        "Select the properties from this event that you would like to set up",
      step_2:
        "Select the marketing program/s and ecosystems to map the event properties to",
    },
    headings: {
      step_1: "Add Event Properties",
      step_2: "Mapping",
      step_3: "Preview & Confirm",
    },
    accordion_details: {
      dataType: "Data Type",
      valueTypes: "Value Types",
    },
    new_event_mapping:
      "Event property mapped to Marketing program successfully",
    placeholders: {
      labels: "Please select one or more labels",
      eventPropertyName: "Please select event property name",
    },
  },
  revise_source_request: {
    headings: {
      step_1: "Segment Source Mapping Revision",
      step_2: "Confirm Segment Source Revision",
    },
    steps: {
      step_1: "Edit Data Sources and Segment Sources to Map",
      step_2: "Confirm the revision of data source and segment source",
    },
  },
  revise_request: {
    headings: {
      step_1: "Revise Request",
      step_3: "Confirm Request Revision",
    },
    steps: {
      step_1: "Edit existing trait or replace trait",
      step_3: "Confirm the following details of your request revision.",
    },
  },
  constants: {
    data_type_options: {
      string: "String",
      integer: "Integer",
      date_time: "Date-time",
      date: "Date",
      float: "Float",
      decimal: "Decimal",
      array: "Array",
      json: "JSON",
      boolean: "Boolean",
    },
    data_class_options: {
      persona_traits: "Persona Traits",
      dependent_traits: "Dependent Traits",
      scores: "Scores",
      enriched_traits: "Enriched Traits",
    },
    trait_type_options: {
      custom_trait: "Custom Trait",
      computed_trait: "Computed Trait",
    },
    trait_input_type_options: {
      freetext_textbox: "Freetext textbox",
      dropdown: "Dropdown",
      checkboxes: "Multi-select checkboxes",
      number_field: "Number field",
      radio_buttons: "Radio buttons",
      collected_offline: "Collected offline",
      purchased_data: "N/A - Purchased data",
      other: "Other",
    },
    trait_response_type_options: {
      single: "Single",
      multiple: "Multiple",
    },
    frequency_of_data_change_options: {
      daily: "Daily",
      more_than_once_week: "More than once a week",
      once_week: "Once a week",
      more_than_once_month: "More than once a month",
      once_month: "Once a month",
      more_than_once_year: "More than once a year",
      once_year_less: "Once a year or less",
    },
    kind_of_data_collected_options: {
      registration_data: "Registration Data",
      clickstream: "Clickstream",
      purchase_data: "Purchase Data",
      dependent_data: "Dependent Data",
      survey_data: "Survey Data",
      enriched_data: "Enriched Data",
    },
    yes_or_no_options: {
      yes: "Yes",
      no: "No",
    },
    source_type_options: {
      standard: "Standard",
      offline: "Offline",
      coregistration: "Coregistration",
    },
    source_integration_type_options: {
      janrain_source: "Janrain source",
      i2_offline: "i2 Offline",
      i5_offline: "i5 Offline",
      clickstream: "Clickstream",
      other_http_api_source: "Other HTTP API Source",
    },
    survivorship_rules: {
      most_recent: "Most Recent",
      first: "First In",
      aggregate: "Aggregated Array",
      source_priority: "Preferred Source",
    },
    channel_options: {
      email: "Email",
      phone: "Phone",
      postal: "Postal",
      email_personal: "Email Personal",
      personal_email: "Personal Email",
      residential_address: "Residential Address",
      email_address: "Email Address",
      phone_number: "Phone Number",
      social_address: "Social Address",
      website_pull: "Website Pull",
      postal_address: "Postal Address",
      mobile_phone: "Mobile Phone",
      line: "Line",
      pull_channel: "Pull channel non PII",
    },
    channel_codes: {
      a: "A",
      e: "E",
      p: "P",
      s: "S",
      w: "W",
    },
    primary_indicator_options: {
      primary: "Primary",
      secondary: "Secondary",
    },
    events_limit_error_message:
      "Only 10 events can be created / reused at a time. Please create another batch of 10 if required",
    traits_limit_error_message:
      "Only 10 traits can be created / reused at a time. Please create another batch of 10 if required",
    personal_data_tooltip:
      "PII (Personally Identifiable Information) includes data that can identify an individual, such as name, SSN, DOB, address, phone numbers, and email.",
    sensitive_data_tooltip:
      "Sensitive (NON-PII) data includes financial info, health info, geolocation, IP addresses, biometrics, behavioral data, social media posts, and religion.",
    health_data_tooltip:
      "This trait is considered health related and must be flagged according to Digital Service Act (DSA).",
    geo_region_code_tooltip:
      "List of all region codes: AMA, APAC, GC, NA, LA, EURO",
    event_category: {
      consumer_action: "Consumer Action",
      email_ctl: "Email CLT",
      iot: "IOT",
      loyalty: "Loyalty",
      postal_CLT: "Postal CLT",
      push_CLT: "Push CLT",
      replenishment: "Replenishment",
      sms_CLT: "SMS CLT",
      sales_transaction: "Sales Transaction",
      website_event: "Website Event",
    },
    action_taken_by_options: {
      pg: "P&G",
      consumer: "Consumer",
    },
  },
  traits_container: {
    traits_management: "Traits Management",
    use_case_management: "Use Case Management",
    traits_responses_management: "Trait Responses Management",
    insights_management: "Insights Management",
    create_trait: "CREATE TRAIT",
    survivorshipRule: "SurvivorShip Rule",
    reuse_trait: "REUSE",
    trait: "Trait",
    cancel_selection: "Cancel Selection",
    my_requests: "My Traits Requests",
    ecosystems_modal_title:
      "has the following mapping details for the Ecosystems below.",
    marketing_programs_modal_title:
      "This trait is used in the following Marketing Programs with specified Ecosystems.",
    search_filter: {
      traits_response_english: "Trait Response English",
      traits_response_local_language: "Trait Response Local Language",
    },
    personal_data_error_message:
      "Selected trait is personal data. {{title}} is not allowed to receive these",
    trait_id_title: "This trait has the following details and mappings",
    insight_id_title: "This insight has the following details and mappings",
    opt_trait: "New Opt Trait",
    errors: {
      select_trait: "Please select a trait",
    },
    empty_mapped_properties_alert: "No Trait Responses Mapped at this level",
    empty_mapped_traits: "No Traits mapped at this level",
    empty_event_properties: "No Event Properties mapped at this level",
  },
  insight_container: {
    create_insight: "Create Insight",
    insight_management: "Insight Management",
    insight_select_or_insight_placeholder:
      "Select existing insight or create new insight",
    headings: {
      step_1: "Add Insight",
      step_2: "Mappings",
      step_3: "Preview and Confirm",
    },
    new_insight: "New Insight",
    no_suggestion_match: "No suggestion match ?",
    new_mp_mapping: "New MP Mapping",
    insight_removal_warning:
      "Are you sure you want to remove this insight? This will delete any input you have provided so far!",
    create_new_insight: "Create New Insight",
    placeholders: {
      insight_name: "Please Enter Insight Name",
      insight_description: "Please Enter The Description",
      sql_expression: "Please Enter SQL Expression",
      sql_expression_indicatoer: "Select SQL Indicatoer",
    },
    insight_marketing_program: "Insight Marketing Program",
    errors: {
      insight_description: {
        error_1: "Insight description should not be same as insight name",
        error_2:
          "Insight description is too short at least must contain 5 non-space characters",
        error_3:
          "Insight description is too short and must be more than three words",
      },
    },
    mp_mapping: {
      marketing_program: "Marketing Program",
      status: "Status",
      compute_frequency: "Compute Frequency",
      batch_schedule: "Batch Schedule",
      processing_type: "Processing Type",
      source_project: "Source Project/Instance",
      destination_project: "Destination Project/Instance",
      placeholders: {
        marketing_program: "Please select marketing program",
        status: "Please select status",
        compute_frequency: "Please select compute frequency",
        batch_schedule: "Please enter batch schedule",
        processing_type: "Please select processing type",
        source_project: "Please enter source project",
        source_instance: "Please enter source instance",
        destination_project: "Please enter destination project",
        destination_instance: "Please enter destination instance",
      },
    },
    manage_insights: "Manage insights",
  },
  consent_container: {
    consent_management: "Consents Management",
    consent_management_header: "Consent Management",
    create_template: "Create Template",
    map_consent: "Map Consent",
    create_new_version: "Create New Version",
    consent_template_key_title:
      "This Consent Template has the following versions",
    create_new_template: "Create New Template",
    consent_mappings_key_title:
      "This Consent ID has the following details and versions",
    modals: {
      versions_modal: {
        full_opt_text: "Full Opt Text",
        opt_text_local_language: "Opt Text Local Language",
        version_number: "Version Number",
        created_date: "Created Date",
        opt_start_date: "Opt Start Date",
        country: "Country",
        language: "Language",
        consentText: "Consent Text",
        noticeText: "Notice Text",
        status: "status",
        startDate: "Start Date",
        endDate: "End Date",
      },
      trait_mapping_modal: {
        service_names: "Service Names",
      },
      text_mapping_modal: {
        contact_point_type_code: "Contact Point Category Type",
        contact_point_category_code: "Contact Point Category Code",
        primary_indicator: "Primary Indicator",
        subscription_opt_number: "Subscription Opt Number",
      },
    },
  },
  traits_usecases: {
    usecase_modal_title:
      "Traits mapped to the use case and the scope is listed below",
    search_filter: {
      usecase_description: "Use Case Description",
    },
    new_usecase: "New Use Case",
  },
  events_usecases: {
    usecase_modal_title:
      "Event Properties mapped to the use case and the scope is listed below",
  },
  traits_requests: {
    tooltip: {
      pending_status: "Request is yet to be reviewed",
      approved_status: "View Request Output",
      returned_status:
        "Request is returned for revisions, Click here to revise the request.",
      processing_status: "Request is being processed in the Queue",
    },
  },
  requests: {
    search_filter: {
      name: "Name",
    },
    my_requests: "My Requests",
    my_approvals: "My Approvals",
    request: "Request",
    requests: "Requests",
    proceed_to_review: "Proceed To Review",
    table_columns: {
      request_date: "Request Date",
      reviewer_name: "Reviewer Name",
      module: "Module",
      opt_name: "Opt Name",
      service_trait_name: "Service / Trait Name",
      marketing_program_description: "Marketing Program",
      batch_review: "Review Multiple",
    },
  },
  traits_mapping_output: {
    ecosystem_not_found: "Ecosystem Trait name not found",
    trait_marketing_program_combination:
      "This trait already exist for this marketing program.",
    trait_marketing_program_ecosystem_combination:
      "This trait already exist for this marketing program-ecosystem combination.",
    trait_response_updated: "Trait response type updated successfully",
    survivorship_rule_updated: "Survivorship rule updated successfully",
  },
  sources_container: {
    sources_management: "Source Management",
    create_source: "CREATE SOURCE",
    create_source_small: "Create Source",
    new_source_id: "New Source ID",
    my_requests: "My Sources Requests",
    ecosystems_modal_title:
      "has the following mapping details for the Ecosystems below.",
    search_filter: {
      url: "URL",
    },
    source_id_title: "This source has the following details and mappings",
    marketing_programs_modal_title:
      "This Data Source is used in the following Marketing Programs",
  },
  marketing_programs_container: {
    refresh: "Refresh",
    marketing_program_management: "MPs Management",
    check_automation_status: "Check Automation Status",
    automation_status: "Automation Status",
    retry_message: "Are you sure want to retry this job",
    empty_jobs_message: "No Automation Jobs Found",
    job: {
      details: {
        start_time: "Start Time",
        end_time: "End Time",
        retry_count: "Retry Count",
        max_retries: "Max Retries",
        retry_job: "Retry Job",
        dependent_job_ids: "Dependent Job IDs",
        filter_title: "Filter Title",
        batch_processing_start_time: "Batch Processing Start Time",
        batch_processing_end_time: "Batch Processing End Time",
        tooltip: {
          message_1:
            "The job will not retry again since it requires manual intervention",
          message_2:
            "The job will not retry again since it's max retry count is reached",
          message_3: "The job will retry again in next couple of minutes",
          message_4:
            "The job will retry again automatically within next 30 minutes",
        },
      },
    },
    headings: {
      step_2: "Confirm New Marketing Program",
      step_3:
        "Request to create a new marketing program submitted successfully!",
    },
    steps: {
      step_1: "Create New Marketing Program",
      step_2: "Confirm New Marketing Program",
    },
    revision: {
      headings: {
        step_1: "Marketing Program Revision",
        step_2: "Confirm Marketing Program Revision",
      },
      steps: {
        step_1: "Revise Marketing Program Request",
        step_2: "Confirm Marketing Program Revision",
      },
    },
    errors: {
      brand_name:
        "Only letters and numbers and {{characters}} characters are allowed",
      brand_short_name:
        "Only letters and numbers and {{characters}} characters are allowed",
      marketing_program_name:
        "Marketing Program Name must be in the format <Brand Short Name / String>-{{countryCode}}-<Single digit integer>.",
      marketing_program_description:
        "Marketing Program Description must be in the format {{countryCode}} <Brand Name / String>",
      required: "This field is required",
    },
    modal_title: "New Marketing Program Details",
    placeholders: {
      legal_entity: "Select Legal Entity",
      brand_name: "Brand Name",
      brand_short_name: "Brand Short Name",
    },
    modals: {
      ecosystems_modal: {
        automation_enabled: "Automation Enabled",
      },
    },
    loading_marketing_program_ecosystems:
      "Loading Marketing Program Ecosystems...",
    ecosystems_modal_title:
      "This marketing program has the following mapping details for below ecosystems:",
  },
  mp_ecosystem_mapping_container: {
    add_ecosystem: "Add Marketing Program Ecosystem",
    new_ecosystem: "New Marketing Program Ecosystem",
    add_ecosystem_mapping: "Add Ecosystem mapping",
    no_ecosystem_mapping: "No Ecosystem mapping found",
    ecosystem_id: "Ecosystem ID",
    ecosystem_name: "Ecosystem Name",
    ecosystem_mp_name: "Ecosystem MP Name",
    ecosystem_mp_key: "Ecosystem MP Key",
    ecosystem_instance: "Ecosystem Instance",
    automation_enabled: "Automation Enabled",
    segment_persona_space: "Has Segment confirmed that Persona space is ready?",
    lytics_secret: "Has CRS team configured Lytics secret?",
    ecosystem_mapping_removal_warning:
      "Are you sure want to delete this ecosystem mapping, This will delete any input you have provided so far!.",
    enable_automation:
      "Entry for this marketing program + ecosystem already exists. If you would like to enable automation for this ecosystem, Please tick the box.",
    tooltips: {
      segment_lytics_hover_info:
        "If you are not sure, please double check this before you proceed submitting. Saying yes here if it is not correct will break many automations.",
      ecosystem_mp_name: "Please provide the {{format}} name",
      ecosystem_mp_key: "{{message}}",
      ecosystem_instance: "{{message}}",
      ecosystem_mp_name_1: "Please provide Ecosystem Marketing Program Name",
      ecosystem_mp_key_1: "Please provide Ecosystem Marketing Program Key",
      ecosystem_instance_1:
        "Please provide Ecosystem Marketing Program Instance",
    },
    mp_eco_system_modal_title:
      "Ecosystems mapped to the Marketing Program is listed below",
    headings: {
      step_1: "Marketing Program Ecosystems Setup",
      step_2: "Confirm Marketing Program Ecosystems Setup",
      step_3:
        "Request to create/update marketing program ecosystems submitted successfully!",
    },
    steps: {
      step_1: "Create Marketing Program Ecosystems",
      step_2: "Confirm Marketing Program Ecosystems",
    },
    revision: {
      headings: {
        step_1: "Marketing Program Ecosystems Revision",
        step_2: "Confirm Marketing Program Ecosystems Request Revision",
      },
      steps: {
        step_1: "Revise Marketing Program Ecosystems Request",
        step_2: "Confirm Marketing Program Ecosystems Request Revision",
      },
    },
    constants: {
      ecosystem_mp_name: {
        segment: "PERSONA",
        lytics: "LYTICS INSTANCE",
        mapp: "MAPP GROUP",
        janrain: "ENTITY TYPE",
      },
      ecosystem_mp_key: {
        segment: "Please provide PERSONA Space ID",
        lytics: "Please provide Lytics Account ID",
        mapp: "Please provide Mapp Group ID",
        janrain: "Skip for Janrain",
      },
      ecosystem_instance: {
        segment: "Please provide Segment Workspace name",
        lytics: "Skip for Lytics",
        mapp: "Please provide Mapp Instance name",
        janrain: "Please provide Janrain regional instance (us, eu, etc...)",
      },
    },
    errors: {
      ecosystem_mp_name: {
        segment:
          "The format must be <ISO-3 country code> <name> (<name> should be at least 3 characters)",
        segment_iso_code: "The ISO-3 country code {{countryCode}} is invalid",
        lytics:
          "The format must be PG - {{deploymentEnv}} - <details> (<details> should be at least 5 characters)",
        mapp: "The format must be at least 5 characters",
        janrain:
          "The format must be <string>_<string>_<number> with string being at least 2 characters",
      },
      ecosystem_mp_key: {
        segment:
          "The format must be spa_<string> (string should be at least 5 characters)",
        decimal_error: "Decimal values are not accepted",
        lytics: "The format must be atleast 4 digits",
        mapp: "The format must be atleast 10 digits",
      },
      ecosystem_instance: {
        segment: "It must be one of {{validInstances}}",
        mapp: "The format must be pg_<string>_<string> with string being at least more than 2 characters",
        janrain: "It must be one of {{validInstances}}",
      },
      segment_persona_space:
        "Segment persona space needs to be enabled for automation",
      lytics_secret: "Lytics secret needs to be configured for automation",
      required: "This field is required",
    },
    messages: {
      mp_ecosystem_combination_exists:
        "Entry for this marketing program + ecosystem already exists and automation is already enabled, please reach out to your data manager if you think there is a problem.",
      ecosystem_already_exists:
        "Entry for this marketing program + ecosystem already exists. If you would like to enable automation for this ecosystem, Please select Enable Automation below.",
      auto_generated_segment_configuration:
        "This ecosystem will be auto generated based on Segment configuration. Please configure Segment ecosystem to populate this ecosystem automatically.",
    },
    modal_title: "New Marketing Program Details",
    placeholders: {
      ecosystem: "Select Ecosystem",
      segment_persona_space: "Select Yes/No",
      lytics_secret: "Select Yes/No",
      enable_automation: "Select Yes/No to Enable/Disable Automation",
    },
    loading_marketing_program_ecosystems:
      "Loading Marketing Program Ecosystems...",
    ecosystems_modal_title:
      "This marketing program has the following mapping details for below ecosystems:",
  },
  marketing_programs_approvals: {
    requestedBy: "Requested By",
    marketing_program_description: "Marketing Program",
  },
  sources_requests: {
    new_data_source: "New Data Source",
  },
  opts_container: {
    opt_management: "Opts Management",
    new_status_trait: "New Status Trait",
    opt_mapping: "Opt Mapping",
    my_requests: "My Opts Requests",
    my_approvals: "My Opts Approvals",
    ecosystems_modal_title:
      "has the following mapping details for the Ecosystems below.",
    table_columns: {
      origin_marketing_program: "Origin Marketing Program",
      opt_text_mappings: "Opt Text Scope Mappings",
      opt_trait_mappings: "Opt Trait Status Mappings",
    },
    search_filter: {
      url: "URL",
      opt_name: "Opt Name",
      opt_trait_name: "Opt Trait Name",
    },
    modals: {
      versions_modal: {
        full_opt_text: "Full Opt Text",
        opt_text_local_language: "Opt Text Local Language",
        version_number: "Version Number",
        created_date: "Created Date",
        opt_start_date: "Opt Start Date",
      },
      trait_mapping_modal: {
        service_names: "Service Names",
      },
      text_mapping_modal: {
        contact_point_type_code: "Contact Point Category Type",
        contact_point_category_code: "Contact Point Category Code",
        primary_indicator: "Primary Indicator",
        subscription_opt_number: "Subscription Opt Number",
      },
    },
    marketing_programs_modal_title:
      "This Data Source is used in the following Marketing Programs",
    versions_modal_title:
      "This opt ID has the below opt texts with the following versionings:",
    versions_modal_loading_text: "Loading Opt Texts and Versionings...",
    trait_mapping_title: "This opt ID has the below opt status trait mappings:",
    trait_mapping_modal_loading_text: "Loading Opt Status Trait Mappings...",
    scope_mapping_title: "This opt ID has the below scope mappings:",
    scope_mapping_modal_loading_text: "Loading scope mappings...",
    new_opt_id: "New Opt ID",
  },
  opts_requests: {
    opt_text_english: "Opt Text English",
    opt_text_local: "Opt Text Local Language",
    click_here: "View Opt Mappings",
  },
  eventsResponses: {
    event_responses_modal_title:
      "Event Property responses mapped to the scope are listed below",
    accordion_keys: {
      language: "Language",
      predefined_event_property_response: "Global Standard Response",
      event_property_response_english: "Event Property Response (English)",
      event_property_response_local_language:
        "Event Property Response (Local Language)",
    },
    search_filter: {
      events_response_english: "Event Property Response English",
      events_response_local_language:
        "Event Property Response (Local Language)",
    },
  },
  events_container: {
    events_management: "Events Management",
    event_properties_loading: "Loading Event Properties...",
    events_responses_management: "Event Property Responses Management",
    events_modal_title:
      "Event properties and marketing program mappings are listed below",
    event_properties_modal_title:
      "These are the event property details for this event.",
    event_properties_marketing_program_title:
      "Event properties mapped to the marketing program are listed below",
    event_accordion_keys: {
      property_name: "Property Name",
      value_types: "Value Types",
    },
    event_properties_keys: {
      required: "Required",
      sample_values: "Sample Values",
      sub_properties: "Sub Properties",
    },
    search_filter: {
      event_description: "Event Description",
      event_property_description: "Event Property Description",
    },
    table_columns: {
      event_description: "Event Description",
      gaca_event_name: "GACA Event Name",
      action_taken_by: "Action Taken By",
      event_properties: "Event Properties",
    },
    errors: {
      no_event_properties: "No Event Properties selected",
    },
    my_requests: "My Events Requests",
    my_approvals: "My Events Approvals",
    view_event_properties: "View Event Properties",
    new_event_properties:
      "New Event Properties to be added to the Event is listed below",
    mapped_properties:
      "Event Properties mapped to the Marketing Program(s) is listed below",
    empty_mapped_properties_alert:
      "No Event Property Responses Mapped at this level",
  },
  revise_opt_request: {
    headings: {
      step_1: "Opt Mapping Revision",
      step_2: "Confirm Opt Mapping Revision",
    },
    steps: {
      step_1: "Revise Opt Mapping",
      step_2: "Confirm Opt Mapping Revision",
    },
  },
  revise_new_opt_request: {
    headings: {
      step_1: "New Opt ID Request Revision",
    },
  },
  opts_mapping: {
    headings: {
      step_1: "New Opt Mapping",
      step_2: "Confirm New Opt Mapping",
      step_3: "Request to create new opt mapping submitted successfully!",
    },
    steps: {
      step_1: "Create Opt Mapping",
      step_2: "Confirm Opt mapping",
    },
    contact_point_category_code: "Contact Point Category",
    primary_indicator: "Primary Indicator",
    subscription_opt_number: "Subscription Opt Number",
    new_service_name: "Create New Service",
    view_service_name: "View Service Name Details",
    modal_title: "Opt Mapping Details",
    validating_service_name: "Validating Service Name...",
    new_service_name_modal: {
      service_name_description: "Service Name Description",
    },
    errors: {
      missing_service_name: "Service Name is required",
      service_name_prefix: "Service Name must be prefixed with {{serviceName}}",
      service_name_suffix:
        "Service Name must contain other than the {{serviceName}}",
      service_name_description:
        "Service Name Description must be different from service name",
      service_name_already_present: "Service Name is already present",
      service_name_valid: "Service Name is valid",
    },
    remove_service_name_removal_warning:
      "Are you sure you want to remove the service name? This will delete any input you have provided so far!.",
    placeholders: {
      consent_id: "Please select the Consent ID",
      opt_id: "Search for Opt ID or Create New Opt ID",
      marketing_program: "Please Select Marketing Program",
      channel: "Please Select Channel",
      contact_point_category_code: "Please Select Contact Point Category Code",
      primary_indicator: "Please Select Primary Indicator",
      service_name: "Please Select Service Name",
      service_loading: "Loading Services",
      subscription_opt_number: "Enter Subscription Opt Number",
    },
    opt_text_uneditable_text:
      "Opt text is retrieved from the database and not editable",
    service_name_marketing_program_error:
      "This service already exists for Marketing Program",
    service_name_marketing_program_combination:
      "This service already exists for this marketing program-opt ID combination.",
  },
  opts_status_trait: {
    headings: {
      step_1: "New Opt Status Trait",
      step_3: "Confirm Opt Status Trait",
      step_4: "Request to Create Opts Status Trait Submitted Successfully",
    },
    steps: {
      step_1: "Opt Traits Management",
      step_2:
        "Select the opt mappings below, If you do not find your opt text below, please coordinate with your local legal team and get your opt text registered in the opt text service.",
      step_3: "Confirm the following details",
    },
    errors: {
      validating_trait_name: "Validating Trait Name...",
      trait_name_already_present:
        "Trait already exists. Mappings will be updated",
      trait_name_valid: "Trait doesn’t exist. Trait will be created",
    },
    status_trait_name_opt_indicator: "Status Trait Name (Opt Indicator)",
    status_trait_name_opt_choice_date: "Status Trait Name (Opt Choice Date)",
    mapping_columns: {
      sub_id: "Sub_Id",
      channel_type: "Channel Type",
    },
    opt_indicator: "OptIndicator",
    opt_choice_date: "OptChoiceDate",
    step_2_message: "You are creating the opt computed trait: ",
    selected_opt_mappings: "Selected Opt Mappings:",
    no_rows_text:
      "No mappings exist for this MP + channel combination, Please add those first.",
    opt_indicator_trait: "Opt Indicator Trait",
    opt_date_trait: "Opt Date Trait",
  },
  new_opt_id: {
    headings: {
      step_1: "Opt Details",
      step_2: "Opt Mappings",
      step_3: "Preview & Confirm",
      step_4: "Request to create opt mappings submitted successfully!",
    },
    steps: {
      step_1: "Please enter Opt Details",
      step_2: "Add Relevant opt mappings",
      step_3: "Confirm the new opt details & mappings below",
    },
    footer: {
      step_1: "PROCEED TO ADD OPT MAPPINGS",
    },
    tooltips: {
      opt_text_english:
        "Opt texts should ideally have channels indicated for consent (ie email, phone, etc) and the brands consumer is opting into. If it does not have this, please ensure you have had it reviewed with legal team",
      opt_text_local:
        "Please enter the Opt text in local language pertaining to the market.",
    },
    opt_text_english: "Opt Text (English)",
    opt_text_local: "Opt Text (Local Language)",
    no_opt_mappings: "No Existing Opt Mappings found",
    select_opt_mappings: "Please Select atleast one opt mapping",
    new_service_name_modal: {
      service_name_description: "Service Name Description",
    },
    errors: {
      opt_text_english: {
        error_1: "Opt Text English must contain minimum of 5 characters",
        error_2: "Opt Text English must contain atleast 3 words",
      },
      opt_text_local: {
        error_1: "Opt Text Local must contain minimum of 5 characters",
        error_2: "Opt Text Local must contain atleast 3 words",
      },
      existing_mapping: "This Opt Mapping already exists.",
      duplicate_subscription_opt_number:
        "This Subscription Opt Number is already present for this Service & Channel Combo",
    },
    placeholders: {
      ciam_marketing_program: "Please Select Marketing Program",
      opt_text_english: "Opt Text (English)",
      opt_text_local: "Opt Text (Local Language)",
    },
    dialogs: {
      warning: "Warning",
      opt_text_warning:
        "Your opt text doesn't seem to mention any channels such as email, phone, SMS, postal etc",
      opt_text_confirmation:
        "Please confirm that you have double checked this with your legal team by clicking below to proceed",
      opt_text_team_check:
        "Otherwise, please stop and go check with them before proceeding",
      approved_by_legal: "Yes, this was approved by legal",
      opt_mapping_warning:
        "Are you sure you want to remove this opt mapping ? This will delete any input you have provided so far!",
      opt_id_warning:
        "Are you sure you want to remove this opt details ? This will delete any input you have provided so far!",
      cancel: "Cancel",
    },
    add_opt_mapping: "Add Opt Mapping",
    new_opt_mapping: "New Opt Mapping",
    request_status: "Request Status",
    opt_id_creation: "Opt Id Created Successfully",
    service_name_creation: "Service Name Created Successfully",
    opt_mapping_creation: "Opt Mapping Created Successfully",
  },
  revise_opts_status_trait: {
    headings: {
      step_1: "Revise Opt Status Trait",
      step_2: "Revise Opt Status Trait",
      step_3: "Confirm Opt Status Trait Revision",
    },
    steps: {
      step_1: "Revise Opt Status Trait",
      step_2:
        "Select the opt mappings below, If you do not find your opt text below, please coordinate with your local legal team and get your opt text registered in the opt text service.",
      step_3: "Confirm the following details",
    },
  },
  reviewModal: {
    request_details: "Request Details",
    edit_request: "Edit Request",
    view_output: "View Output",
    original_version: "Original Version",
    required_rejection_returning_revision:
      "Required for Rejection and Returning for Revision",
  },
  dialogs: {
    confirm_remove: "Confirm Remove",
    confirm_approval: "Confirm Approval",
    confirm_reject: "Confirm Reject",
    confirm_return: "Confirm Return",
    confirm_approval_message: "Are you sure want to approve this request?",
    confirm_batch_approval_message:
      "Are you sure want to approve this group of requests?",
    confirm_rejected_message: "Are you sure want to reject this request?",
    confirm_batch_rejected_message:
      "Are you sure want to reject this group of requests?",
    confirm_returned_message:
      "Are you sure want to return this request for revision?",
    confirm_batch_returned_message:
      "Are you sure want to return this group of requests?",
    confirm_retry: "Confirm Retry",
    confirm_disconnect: "Confirm Disconnection",
  },
  access_denied: {
    status: "403",
    title: "Access Denied!",
    you_do_not_have_access: "Sorry, you do not have access to this page",
    go_to_traits: "GO TO TRAITS",
    go_to_events: "GO TO EVENTS",
    go_to_sources: "GO TO SOURCES",
    go_to_opts: "GO TO OPTS",
    go_to_mps: "GO TO MARKETING PROGRAMS",
    go_to_consents: "GO TO CONSENTS",
  },
  ecosystems: {
    modal_title: "has the following mapping details for the Ecosystems below.",
    loading_mappings: "Loading ecosystem mappings ...",
    no_mapping: "No Ecosystems mapped",
    no_sources_mapping: "No Ecosystem sources mapped",
    personal_data_exception: "Personal Data Exception",
    create_traits_keys: {
      ecosystem_trait_misc_property: "Ecosystem Trait Misc. Property 1",
    },
    create_source_keys: {
      ecosystem_source_name: "Ecosystem Source Name",
      ecosystem_source_type: "Ecosystem Source Type",
      ecosystem_source_key: "Ecosystem Source Key",
      ecosystem_source_link: "Ecosystem Source Link",
      ecosystem_source_slug: "Ecosystem Source Slug",
    },
  },
  relatedTraits: {
    loading_mappings: "Loading related traits ...",
    loading_event_properties: "Loading event properties ...",
  },
  traitsResponses: {
    traits_responses_modal_title:
      "Trait responses mapped to the scope are listed below",
    accordion_keys: {
      language: "Language",
      predefined_trait_response: "Global Standard Response",
      trait_response_english: "Trait Response (English)",
      trait_response_local_language: "Trait Response (Local Language)",
      predefined_trait_response_aliases: "Trait Response Aliases",
    },
  },
  marketing_programs: {
    no_mapping: "No Traits / Marketing Programs mapped",
    service_marketing_program: "Service Marketing Program",
    opt_marketing_program: "Opt Marketing Program",
  },
  sidebar_menu_config: {
    search_traits: "Search Traits",
    my_tasks: "My Tasks",
    sources: "Sources",
    search_sources: "Search Sources",
    search_opts: "Search Opts",
    search_mps: "Search MPs",
    help_me: "Help Me",
    user_guide: "User Guide (Confluence)",
    contact_us: "Contact Us (SNOW)",
    monitoring: "Monitoring",
    dummy: "Dummy",
    search_jobs: "Search Jobs",
    search_events: "Manage Events",
    feedback: "Rate Us",
  },
  request_output: {
    output: "Output",
    source_modal_title:
      "Data source and Data source marketing program mapping has been added to CRS with the following details:",
    opts_modal_title:
      "Opts mapping has been added to CRS with the following details:",
    opt_id_modal_title:
      "Opt & Opt mappings has been added to CRS with the following details:",
    marketing_program_modal_title:
      "Marketing program and it's mappings have been added to CRS with the following details:",
    ecosystem_marketing_program_modal_title:
      "Marketing Program and it's Ecosystem mappings have been added to CRS with the following details:",
    segment_source_modal_title:
      "Data Source and Segment Source mapping has been added to CRS with the following details:",
    modal_title:
      "Trait and trait mapping has been added to CRS with the following details:",
    opts_trait_modal_title:
      "Opts Trait mapping has been added to CRS with the following details:",
    use_case_modal_title:
      "Use Case along with associated traits have been added to CRS with the following details:",
    use_case_event_properties_modal_title:
      "Use Case along with associated event properties have been added to CRS with the following details:",
    traits_responses_modal_title: "Trait Responses output",
    events_responses_modal_title: "Event Property Responses output",
    global_standard_response_modal_title: "Global Standard Responses output",
    event_modal_title:
      "Event Properties mapping with marketing programs have been added to CRS with the following details:",
    consent_template_modal_title: "Consent Template output",
    event_output_modal_title:
      "New Event and Event Properties have been added to CRS with the following details:",
    insight_output_modal_title:
      "New Insight have been added to CRS with the following details:",
  },
  autocomplete: {
    trait_name_placeholder:
      "Type a trait name or trait description... (example: hair color)",
    trait_select_or_create_placeholder:
      "Select existing trait or create new trait",
    marketing_program_placeholder: "Example: IND GrowingFamilies",
    ecosystem_placeholder: "Example: Segment",
    event_select_or_event_placeholder:
      "Select existing event or create new event",
    data_source_placeholder:
      "Type a Data source name Example: i2/?/1/IND/Asia/GHH/Direxions",
  },
  jobs_monitoring_container: {
    recent_job_services: "Jobs Management",
    create_job: "Create Job",
    create_new_job: "Create New Job",
    view_details_1: "VIEW DETAILS",
    job_details_for: "Job Details For",
    job_services: "Job Services",
    create_job_modal: {
      ecosystem_source_id: "Ecosystem Source Id",
    },
    select_option: "Select Option",
    select_job: "Select Job",
    errors: {
      marketing_program_number:
        "Marketing Program Number is required for this Job, if Ecosystem Source Id is provided",
    },
    table_columns: {
      average_job_running_time: "Average Job Running Time",
      average_number_of_failures: "Average # of Failures",
      average_number_of_jobs_more_than_five_minutes:
        "Average # of Jobs Taking 5 Minutes and longer",
      job_start_time: "Job Start Time",
      job_end_time: "Job End Time",
    },
    services: {
      lyticsQueries: "Lytics Queries",
      lyticsEvents: "Lytics Events",
      pubsubTraits: "Pubsub Traits",
      pubsubOpts: "Pubsub Opts",
      pubsubMarketingPrograms: "Pubsub Marketing Programs",
      mapp_email: "Mapp Emails",
      segmentTrackingPlans: "Segment Tracking Plans",
      segmentSources: "Segment Sources",
      segmentDestinations: "Segment Destinations",
      resciEmails: "Resci Emails",
    },
  },
};
