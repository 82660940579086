const createBackendPayload = (consentTemplate, newVersion) => {
  return {
    consentTemplateType: {
      consentTemplateTypeId: consentTemplate.consentTemplateTypeId,
      description:
        consentTemplate?.description || consentTemplate.consentTemplateType,
    },
    country: {
      countryCode: consentTemplate.countryCode,
      countryName: consentTemplate.countryName || consentTemplate.country,
    },
    language: {
      languageCode: consentTemplate.languageCode,
      languageName: consentTemplate.languageName || consentTemplate.language,
    },
    templateVersionNumber: newVersion.templateVersionNumber,
    consentText: `<div class='crs-consent'>${newVersion.consentText}</div>`,
    noticeText: `<div class='crs-notice'>${newVersion.noticeText}</div>`,
  };
};

export default createBackendPayload;
