import React from "react";

import { Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import { ReactComponent as ContentCopyOutlined } from "../../assets/images/copy.svg";

import useGlobalStyles from "../../assets/styles/global";

import isEmpty from "../../utilities/isEmpty";
import truncateString from "../../utilities/truncateString";

import useStyles from "./styles";

export const KeyValueSeparator = ({
  keyVal,
  value,
  separator,
  ind,
  copyValue,
}) => {
  const classes = useStyles();

  const handleCopy = (copyValueString) => {
    let textToCopy = "";

    if (typeof copyValueString === "string") {
      textToCopy = copyValueString;
    } else if (React.isValidElement(copyValueString)) {
      if (copyValueString.props.dangerouslySetInnerHTML) {
        // eslint-disable-next-line no-underscore-dangle
        const html = copyValueString.props.dangerouslySetInnerHTML.__html;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        const pTag = tempDiv.querySelector("p");
        if (pTag) {
          textToCopy = pTag.innerText || pTag.textContent;
        } else {
          textToCopy = html;
        }
      } else if (copyValueString.props.children) {
        textToCopy =
          typeof copyValueString.props.children === "string"
            ? copyValueString.props.children
            : copyValueString.props.children?.toString() || "";
      }
    } else {
      textToCopy = copyValueString?.toString() || "";
    }

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log("Copied to clipboard:", textToCopy);
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    }
  };

  return (
    <tr key={ind?.toString()} className={classes.row}>
      {copyValue ? (
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" className={classes.featureName}>
              {keyVal}
            </Typography>
            <IconButton onClick={() => handleCopy(value)}>
              <ContentCopyOutlined
                style={{
                  fill: "#3682F2",
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </div>
        </td>
      ) : (
        <td>
          <Typography variant="body2" className={classes.featureName}>
            {keyVal}
          </Typography>
        </td>
      )}
      {separator && (
        <td>
          <Typography className={classes.separator}>{separator}</Typography>
        </td>
      )}
      <td>
        {(() => {
          if (typeof value === "string") {
            const truncatedValue = truncateString(value, {
              maxLength: 90,
              showEllipsis: true,
            });
            if (truncatedValue === value) {
              return (
                <Typography
                  variant="body2"
                  title={value}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {value}
                </Typography>
              );
            }
            return (
              <Tooltip title={value} className={classes.customTooltip}>
                <Typography
                  variant="body2"
                  title={truncatedValue}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {truncatedValue}
                </Typography>
              </Tooltip>
            );
          }
          if (Array.isArray(value)) {
            return <div className={classes.flexContainer}>{value}</div>;
          }
          return value;
        })()}
      </td>
    </tr>
  );
};

KeyValueSeparator.defaultProps = {
  separator: ":",
  copyValue: false,
};

KeyValueSeparator.propTypes = {
  keyVal: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  separator: PropTypes.string,
  ind: PropTypes.number.isRequired,
  copyValue: PropTypes.bool,
};

const AccordionRowDetails = ({ defaultValue, keys, row }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  if (!row) {
    return <></>;
  }
  if (Object.keys(row).length === 0) {
    return <></>;
  }

  const isJson = (item) => {
    if (Array.isArray(item)) {
      return false;
    }
    let parsedItem = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      parsedItem = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof parsedItem === "object" && parsedItem !== null) {
      return true;
    }

    return false;
  };

  const convertToUiFormat = (objectKeys, rowValues) => {
    return objectKeys?.map((key, j) => {
      let value = defaultValue;
      if (key.id && key.id.includes(".")) {
        const levels = key.id.split(".");
        if (
          levels.length === 2 &&
          rowValues[levels[0]] &&
          rowValues[levels[0]][levels[1]]
        ) {
          value = rowValues[levels[0]][levels[1]].toString();
        } else if (
          levels.length === 3 &&
          rowValues[levels[0]] &&
          rowValues[levels[0]][levels[1]] &&
          rowValues[levels[0]][levels[1]][levels[2]]
        ) {
          value = rowValues[levels[0]][levels[1]][levels[2]].toString();
        }
      } else if (!isEmpty(rowValues[key.id])) {
        if (key.preformat) {
          const formatName = rowValues[key.formatNameKey] || key.formatName;
          const data = rowValues[key.id];
          value = (
            <details>
              <summary>
                <span>{formatName} data</span>
                <Button
                  type="button"
                  style={{ marginLeft: "8px" }}
                  onClick={(e) => {
                    navigator.clipboard.writeText(data);
                    e.target.innerHTML = "Copied";
                    setTimeout(() => {
                      e.target.innerHTML = `Copy ${formatName}`;
                    }, 2000);
                  }}
                >
                  Copy {formatName}
                </Button>
              </summary>
              <pre>{data}</pre>
            </details>
          );
        } else if (
          typeof rowValues[key.id] === "object" &&
          !Array.isArray(rowValues[key.id])
        ) {
          value = rowValues[key.id];
        } else if (
          Array.isArray(rowValues[key.id]) &&
          rowValues[key.id].length > 0 &&
          rowValues[key.id][0].type === "div"
        ) {
          value = rowValues[key.id];
        } else if (
          Array.isArray(rowValues[key.id]) &&
          rowValues[key.id].length > 0 &&
          typeof rowValues[key.id][0] === "object"
        ) {
          value = rowValues[key.id].map((x) => x.title).toString();
        } else if (isJson(rowValues[key.id])) {
          const data = JSON.stringify(JSON.parse(rowValues[key.id]), null, 2);
          value = (
            <details>
              <summary>
                <span>JSON data</span>
                <Button
                  type="button"
                  style={{ marginLeft: "8px" }}
                  onClick={(e) => {
                    navigator.clipboard.writeText(data);
                    e.target.innerHTML = "Copied";
                    setTimeout(() => {
                      e.target.innerHTML = "Copy JSON";
                    }, 2000);
                  }}
                >
                  Copy JSON
                </Button>
              </summary>
              <pre>{data}</pre>
            </details>
          );
        } else if (/<[^>]+>/.test(rowValues[key.id])) {
          value = (
            <span
              style={{ fontSize: "0.875rem" }}
              dangerouslySetInnerHTML={{ __html: rowValues[key.id] }}
            />
          );
        } else {
          value = rowValues[key.id]?.toString();
        }
      }
      return (
        <KeyValueSeparator
          key={j}
          keyVal={key.name}
          value={value}
          ind={j}
          separator={null}
          copyValue={key.copyValue}
        />
      );
    });
  };

  if (!Array.isArray(row)) {
    return (
      <table className={globalClasses.table}>
        <tbody>{convertToUiFormat(keys, row)}</tbody>
      </table>
    );
  }
  if (Array.isArray(row)) {
    return row.map((rowItem, index) => {
      return (
        <table className={clsx(index > 0 && classes.row, globalClasses.table)}>
          <tbody>{convertToUiFormat(keys, rowItem)}</tbody>
        </table>
      );
    });
  }

  return <></>;
};

AccordionRowDetails.defaultProps = {
  defaultValue: "",
};

AccordionRowDetails.propTypes = {
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }).isRequired,
    PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ).isRequired,
  ]).isRequired,
  defaultValue: PropTypes.string,
};

export default AccordionRowDetails;
