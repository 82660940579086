import { useEffect, useMemo, useState, Fragment } from "react";

import { TextField, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CustomAutoComplete from "../../../../components/CustomAutoComplete";
import InlineMessage from "../../../../components/InlineMessage";
import isEmpty from "../../../../utilities/isEmpty";
import useConsentTemplateTypesAndCountries from "../../NewConsentTemplateContainer/helpers/useConsentTemplateTypesAndCountries";
import useStyles from "../ConsentID/styles";
import extractNamesFromTemplate from "../helpers/extractNamesFromTemplate";
import validateConsentTexts from "../helpers/validateConsentTexts";

import TemplateTexts from "./TemplateTexts";

const ConsentTexts = ({
  showValidation,
  consentTemplates,
  countryValue,
  setCountryValue,
  languagesValue,
  setLanguagesValue,
  updatedConsentTexts,
  setUpdatedConsentTexts,
  templateVariablesValues,
  setTemplateVariablesValues,
  mpCountryCode,
  consentMappingInfo,
  marketingProgramValue,
  openAccordion,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { countries, loading: countriesLoading } =
    useConsentTemplateTypesAndCountries();
  const [languageInputValue, setLanguageInputValue] = useState(
    languagesValue?.title || ""
  );
  const [focused, setFocused] = useState({
    country: false,
    language: false,
  });
  const errors = useMemo(
    () =>
      validateConsentTexts({
        countryValue,
        updatedConsentTexts,
        languagesValue,
        templateVariablesValues,
      }),
    [countryValue, updatedConsentTexts, languagesValue, templateVariablesValues]
  );
  const filteredCountries = useMemo(() => {
    const uniqueCountries = new Set();
    const result =
      consentTemplates?.reduce((acc, c) => {
        const foundCountry = countries.find((c2) => c2.country === c.country);
        if (foundCountry && !uniqueCountries.has(foundCountry.country)) {
          uniqueCountries.add(foundCountry.country); // Add to set if unique
          acc.push(foundCountry);
        }
        return acc;
      }, []) || [];
    return result;
  }, [consentTemplates, countries]);

  const [defaultCountryInitialized, setDefaultCountryInitialized] =
    useState(false);

  useEffect(() => {
    if (
      !defaultCountryInitialized &&
      mpCountryCode &&
      filteredCountries?.length > 0 &&
      isEmpty(countryValue)
    ) {
      const defaultCountry = filteredCountries.find(
        (c) => c.countryCode === mpCountryCode
      );
      if (defaultCountry) {
        const country = {
          ...defaultCountry,
          title: `${defaultCountry.countryCode} - ${defaultCountry.country}`,
        };
        setCountryValue(country);
      }
      setDefaultCountryInitialized(true);
    }
    if (!isEmpty(countryValue) && !defaultCountryInitialized) {
      setDefaultCountryInitialized(true);
    }
  }, [countryValue, filteredCountries, defaultCountryInitialized]);

  const languages = useMemo(() => {
    const uniqueLanguages = new Set();
    const result =
      consentTemplates?.reduce((acc, c) => {
        if (c.country === countryValue?.country) {
          const languageKey = `${c.languageCode}-${c.language}`; // Create a unique key for each language

          if (!uniqueLanguages.has(languageKey)) {
            uniqueLanguages.add(languageKey);
            acc.push({
              ...c,
              languageCode: c.languageCode,
              languageName: c.language,
            });
          }
        }

        return acc;
      }, []) || [];

    return result;
  }, [consentTemplates, countryValue]);

  useEffect(() => {
    const english = languages.find((l) => l.languageCode === "EN");
    if (english) {
      if (!languagesValue.find((l) => l.languageCode === "EN")) {
        setLanguagesValue((l) => [
          ...l,
          {
            ...english,
            title: `${english.languageCode} - ${english.languageName}`,
          },
        ]);
      }
    }
  }, [languages, languagesValue]);

  const selectedConsentTemplates = useMemo(() => {
    return (
      consentTemplates?.filter((c) =>
        languagesValue.find(
          (l) => l.languageCode === c.languageCode && c.country === l.country
        )
      ) || []
    );
  }, [consentTemplates, languagesValue]);

  useEffect(() => {
    const existingConsentTexts = { ...updatedConsentTexts };
    const newConsentTexts = {};
    selectedConsentTemplates.forEach((consentTemp) => {
      if (
        isEmpty(
          existingConsentTexts[
            `${consentTemp.consentTemplateTypeId}-${consentTemp.countryCode}-${consentTemp.languageCode}`
          ]
        )
      ) {
        newConsentTexts[
          `${consentTemp.consentTemplateTypeId}-${consentTemp.countryCode}-${consentTemp.languageCode}`
        ] = {
          consentText: consentTemp.consentText,
          noticeText: consentTemp.noticeText,
          consentTemplateId: consentTemp.consentTemplateId,
          consentTemplateType: consentTemp.consentTemplateType,
          consentTemplateTypeId: consentTemp.consentTemplateTypeId,
          countryCode: consentTemp.countryCode,
          languageCode: consentTemp.languageCode,
          language: consentTemp.language,
          consentId: `${marketingProgramValue?.marketingProgramNumber}_${consentTemp.consentTemplateTypeId}`,
        };
      } else {
        newConsentTexts[
          `${consentTemp.consentTemplateTypeId}-${consentTemp.countryCode}-${consentTemp.languageCode}`
        ] =
          existingConsentTexts[
            `${consentTemp.consentTemplateTypeId}-${consentTemp.countryCode}-${consentTemp.languageCode}`
          ];
      }
    });
    setUpdatedConsentTexts(newConsentTexts);
  }, [selectedConsentTemplates]);

  const templateVariables = useMemo(() => {
    const templateVars = new Set();
    selectedConsentTemplates.forEach((selectedConsentTemplate) => {
      const consentText = selectedConsentTemplate?.consentText;
      const noticeText = selectedConsentTemplate?.noticeText;
      const extractedNames = extractNamesFromTemplate(consentText, noticeText);
      extractedNames.forEach((variable) => templateVars.add(variable));
    });
    return [...templateVars].filter((i) => !isEmpty(i));
  }, [selectedConsentTemplates]);

  useEffect(() => {
    if (languages && languages?.length === 0) {
      setFocused((f) => ({
        ...f,
        language: true,
      }));
    }
  }, []);

  return (
    <div className={classes.grid}>
      {countriesLoading && (
        <div style={{ padding: "8px" }}>
          {Array.from({ length: 3 }).map((_, i) => (
            <Fragment key={i}>
              <Skeleton variant="rect" width="100%" height={60} />
              <br />
            </Fragment>
          ))}
        </div>
      )}

      {!countriesLoading ? (
        <div className={classes.inputContainer}>
          <Typography style={{ fontWeight: "500", fontSize: "16px" }}>
            {t("common.labels.language")}:
            <p style={{ fontWeight: "400", fontSize: "16px" }}>
              <i>(Please select the language/s to be used for this mapping.)</i>
            </p>
          </Typography>

          <CustomAutoComplete
            disableFilterOptions
            id="language"
            placeholder={t("create_consent_template.placeholders.language")}
            options={languages.map((l) => ({
              ...l,
              languageCode: l.languageCode.toUpperCase(),
              title: `${l.languageCode.toUpperCase()} - ${l.languageName}`,
            }))}
            value={languagesValue}
            inputValue={languageInputValue || languagesValue?.title}
            setValue={(value) => {
              if (
                (languagesValue.find((l) => l.languageCode === "EN") &&
                  !value.find((l) => l.languageCode === "EN")) ||
                (consentMappingInfo?.consentIds?.length
                  ? consentMappingInfo?.consentIds?.forEach((item) => {
                      item.consentVersions.filter((v) => {
                        const languageCode = v.language.split(" - ")[0];
                        return !value.find(
                          (l) => l.languageCode === languageCode
                        );
                      });
                    })?.length > 0
                  : true)
              ) {
                return;
              }
              setLanguagesValue(value);
              setLanguageInputValue(value?.title);
            }}
            onInputChange={setLanguageInputValue}
            onBlur={() => {
              if (!focused.language) {
                setFocused((f) => ({
                  ...f,
                  language: true,
                }));
              }
            }}
            error={
              (focused.language || showValidation) &&
              errors.languagesError !== undefined
            }
            errorText={errors.languagesError}
          />

          {templateVariables.map((variable) => (
            <div className={classes.inputContainer}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  paddingTop: "16px",
                }}
              >
                {variable}:
              </Typography>
              <TextField
                value={templateVariablesValues[variable] || ""}
                onChange={(event) => {
                  setTemplateVariablesValues((x) => ({
                    ...x,
                    [variable]: event.target.value,
                  }));
                }}
                onBlur={() => {
                  if (!focused.country) {
                    setFocused((f) => ({
                      ...f,
                      [variable]: true,
                    }));
                  }
                }}
                required
                variant="outlined"
                error={
                  (focused[variable] || showValidation) &&
                  errors[variable] !== undefined
                }
              />
              {(focused[variable] || showValidation) && errors[variable] && (
                <InlineMessage state="error" message={errors[variable]} />
              )}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {!countriesLoading && (
        <>
          {!errors.languagesError && (
            <TemplateTexts
              updatedConsentTexts={updatedConsentTexts}
              setUpdatedConsentTexts={setUpdatedConsentTexts}
              templateVariablesValues={templateVariablesValues}
              onSubmit={(mapping) => setUpdatedConsentTexts(mapping)}
              openAccordion={openAccordion}
            />
          )}
        </>
      )}
    </div>
  );
};

ConsentTexts.defaultProps = {
  showValidation: false,
  consentTemplates: [],
  openAccordion: false,
};

ConsentTexts.propTypes = {
  showValidation: PropTypes.bool,
  consentTemplateTypeValue: PropTypes.shape({
    consentTemplateTypeId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  countryValue: PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  languagesValue: PropTypes.arrayOf({
    languageCode: PropTypes.string.isRequired,
    languageName: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  consentTemplates: PropTypes.arrayOf(PropTypes.shape),
  setCountryValue: PropTypes.func.isRequired,
  setLanguagesValue: PropTypes.func.isRequired,
  updatedConsentTexts: PropTypes.objectOf(PropTypes.string).isRequired,
  setUpdatedConsentTexts: PropTypes.func.isRequired,
  templateVariablesValues: PropTypes.objectOf(PropTypes.string).isRequired,
  setTemplateVariablesValues: PropTypes.func.isRequired,
  mpCountryCode: PropTypes.string.isRequired,
  marketingProgramValue: PropTypes.shape({
    marketingProgramNumber: PropTypes.number.isRequired,
  }).isRequired,
  consentMappingInfo: PropTypes.shape({
    consentVersions: PropTypes.arrayOf({
      language: PropTypes.string,
    }),
    consentIds: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  // eslint-disable-next-line react/prop-types
  openAccordion: PropTypes.bool,
};

export default ConsentTexts;
