import { useState, useRef } from "react";

import { Paper, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getConsentMappings from "../../../api/get-consent-mappings";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import consentsModuleConfig from "../../../config/consentsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import useStyles from "../../Traits/TraitsContainer/styles";

import ConsentTemplateVersionsModal from "./components/ConsentMappingsModal";

const ConsentMappingsContainer = () => {
  const {
    consentMappingsTableColumns,
    consentSearchFilters,
    consentSearchDefaultFilterState,
  } = consentsModuleConfig;
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const childRef = useRef();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "consentId",
    row: {},
  });

  const { user } = useUserProfile();
  const { t } = useTranslation();
  const { loading } = useLoadingSpinner();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchTraits
  );

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "consentId",
      row: {},
    });
  };

  const filterFieldMap = {
    consentId: "consentId",
    marketingProgram: "marketingProgram",
    country: "country",
    language: "language",
    "": "searchText",
  };

  return !loading && !isUserAuthorized ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("consent_container.consent_management_header")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Consents",
              onClick: () => history.push("/consents"),
            },
            {
              label: "Templates",
              onClick: () => history.push("/consents?displayTab=templates"),
            },
          ]}
          classes={{
            indicator: globalStyles.indicator,
          }}
        />
        <div className={classes.flexContainer}>
          <div className={classes.flexContainer}>
            {checkUserAuthorization(
              user.access,
              pageAccessConfig.manageConsents
            ) && (
              <Button
                btnText={t("consent_container.map_consent")}
                variant="contained"
                classes={{
                  root: globalStyles.btn,
                }}
                goToLink="/consents/manage"
                style={{
                  marginRight: "34px",
                }}
              />
            )}
            <SearchFilter
              ref={childRef}
              onSearch={(data) => {
                setFilters(data);
              }}
              searchFilters={consentSearchFilters}
              defaultFilterState={consentSearchDefaultFilterState}
            />
            <StyledTooltip
              placement="top"
              title={t("common.labels.clear_search")}
            >
              <div
                role="button"
                aria-hidden="true"
                data-testid="reset-btn"
                onClick={resetFilter}
              >
                <ClearIcon data-testid="filter-icon" />
              </div>
            </StyledTooltip>
          </div>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getConsentMappings(
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="consents"
            filters={filters}
            columns={consentMappingsTableColumns}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            exportMainSheetName="consents"
            openModal={() => setDrawerOpen(true)}
          />
        </div>
      </Paper>
      {cellData.field === "consentId" && drawerOpen && (
        <ConsentTemplateVersionsModal
          title={`${t("common.labels.consent_id")} - ${cellData.row.consentId}`}
          subtitle={t("consent_container.consent_mappings_key_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

ConsentMappingsContainer.defaultProps = {
  basePath: applicationConfig.basePaths.consent,
};

export default ConsentMappingsContainer;
